import React, { useState, Fragment, useRef } from 'react';
import SimpleMap from 'components/common/SimpleMap/SimpleMap';
import { Button } from 'components/common/Button/Button';
import PlacesAutocomplete, { AddressResponse, Place } from 'components/common/Form/PlacesAutocomplete';
import useMapLoader from 'components/common/SimpleMap/useMapLoader';
import { ContextValue, useCtx } from 'components/common/Form/FormDataProvider/FormDataProvider';
import { DefaultFormStateType } from 'components/common/Form/types/types';
import styles from './AddListingAddress.module.scss';
import Modal from 'components/common/Modal';
import { postToLandApi } from 'components/common/utils/helpers';
import { ListingFormData } from 'components/pages/Listing/defaultData';
import { useHistory } from 'react-router';
import AddressEditGroup from 'components/pages/Listing/PropertyAddress/AddressEditGroup';

import { MemoryRouter } from 'react-router-dom';
import { useStore } from 'components/store';

export default function AddListingAddress(): JSX.Element {
  const isMapLoaded = useMapLoader();
  const [provided, setProvided] = useCtx() as ContextValue<DefaultFormStateType<Place>>;
  const location = provided?.place?.geometry?.location;
  const viewport = provided?.place?.geometry?.viewport;
  const [isExpanded, setIsExpanded] = useState(true);
  const [displayMap, setDisplayMap] = useState(true);
  const [isListingFlowActive, setListingFlowState] = useStore(state => [
    state.isListingFlowActive,
    state.setListingFlowState
  ]);
  const history = useHistory();
  const addressRef = useRef<AddressResponse>();
  const mapRef = useRef<google.maps.LatLngLiteral>();
  const [addy, setaddy] = useState({} as AddressResponse);
  const [, setMarker] = useState({} as google.maps.LatLngLiteral);
  const [isPosting, setIsPosting] = useState(false);

  const onAddressUpdate = (addressResponse: AddressResponse): void => {
    addressRef.current = addressResponse;
    setaddy(addressResponse);
  };

  const handleEditAddress = (): void => setDisplayMap(!displayMap);

  // This is the first save and sent to create a new listing.
  const handleSaveAddress = async (val: unknown, newpos: unknown): Promise<void> => {
    setIsPosting(true);
    const response = await postToLandApi<ListingFormData>('/Listing/add-listing', val);

    if (response.ok) {
      const listingId = response.data as unknown as number;

      // Save 1st Marker posistion from simple map to the db after response
      await firstSaveOfMapMarker(listingId, newpos as unknown as google.maps.LatLngLiteral);

      setIsExpanded(false);
      toggleAddModal(false);
      setIsPosting(false);
      history.push(`/listing/edit/${response.data}`);
    } else {
      console.error(response.errors);
      setIsPosting(false);
    }
  };

  const toggleAddModal = (isActiveOverride?: boolean): void => {
    setListingFlowState(typeof isActiveOverride === 'boolean' ? isActiveOverride : !isListingFlowActive);
  };

  const closeModal = (): void => {
    setIsExpanded(false);
    toggleAddModal(false);
    onAddressUpdate({} as AddressResponse);
    setProvided(state => ({ ...state } as DefaultFormStateType<Place>));
  };

  const returnMainModal = (): void => {
    handleEditAddress();
  };

  const saveMarker = (newpos: google.maps.LatLngLiteral): void => {
    setMarker(newpos);
    const mapMarker = {
      lat: newpos.lat,
      lng: newpos.lng
    } as unknown as google.maps.LatLngLiteral;
    mapRef.current = mapMarker;
  };

  return (
    <div>
      {!displayMap && (
        <Modal title="Add Your Listing" close={returnMainModal} isExpanded={isExpanded}>
          <AddressEditGroup address={addy} />
        </Modal>
      )}
      {displayMap && (
        <Modal title="Add Your Listing" close={closeModal} isExpanded={isExpanded}>
          <Fragment>
            <PlacesAutocomplete isMapApiLoaded={isMapLoaded} onUpdate={onAddressUpdate} />
            <div className={styles['edit-link']}>
              <Button kind="text" onClick={handleEditAddress} isDisabled={addressRef.current?.address == null}>
                Edit Address
              </Button>
            </div>

            <SimpleMap
              isMapApiLoaded={isMapLoaded}
              location={location as unknown as google.maps.LatLngLiteral}
              viewport={viewport as unknown as google.maps.LatLngBoundsLiteral}
              saveMarker={saveMarker}
            ></SimpleMap>
            <div className="button-container-center">
              <MemoryRouter>
                <Button
                  className={styles.button}
                  isDisabled={isPosting || addressRef.current?.address == null}
                  onClick={(): void => {
                    if (mapRef.current === undefined) {
                      const mapMarker = {
                        lat: addressRef.current?.latitude,
                        lng: addressRef.current?.longitude
                      } as unknown as google.maps.LatLngLiteral;
                      mapRef.current = mapMarker;
                    }
                    handleSaveAddress(addressRef.current, mapRef.current);
                  }}
                >
                  Get Started
                </Button>
              </MemoryRouter>
            </div>
          </Fragment>
        </Modal>
      )}
    </div>
  );
}

export const firstSaveOfMapMarker = async (
  listingId: number,
  location: google.maps.LatLngLiteral | undefined
): Promise<void> => {
  await postToLandApi<ListingFormData>(
    '/Listing/add-simple-marker',
    { ListingId: listingId, position: location } as unknown,
    'addMarker'
  );
};
