import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './AppHeader.module.scss';
import Icon from 'components/common/Icons';
import { useStore } from 'components/store';
import MyAccount from 'components/common/MyAccount';
import AddListingAddress from 'components/common/Form/AddressInput/AddListingAddress';
import FormDataProvider from 'components/common/Form/FormDataProvider/FormDataProvider';
import { initialFormData } from 'components/pages/Listing/defaultData';

export interface Header {
  isExpanded?: boolean;
  toggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AppHeader(props: Header): JSX.Element {
  const { toggleMenu, isExpanded } = props;
  const history = useHistory();
  const [isListingFlowActive] = useStore(state => [state.isListingFlowActive]);
  const [syncProfileStoreApi, profile] = useStore(state => [state.syncProfileStoreApi, state.profile]);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (profile.accountId === 0) {
      syncProfileStoreApi();
    }
  }, [profile, syncProfileStoreApi]);

  return (
    <Fragment>
      <div data-testid="shell-appheader" className={styles.appheader}>
        <div
          role="button"
          tabIndex={0}
          onClick={(): void => toggleMenu(!isExpanded)}
          className={styles['hamburger-icon']}
        >
          <Icon name={'hamburger'} />
        </div>
        <div
          data-testid="icon-btn-logo"
          className={styles['nav-logo']}
          role="button"
          tabIndex={0}
          onClick={(): void => history.push('/')}
        >
          <Icon name="mhLogo" className="logo-mobile" />
          {isListingFlowActive && (
            <FormDataProvider state={formData} setState={setFormData}>
              <AddListingAddress />
            </FormDataProvider>
          )}
        </div>
        <div className={styles['account-info']} role="button" tabIndex={0} onClick={(): void => toggleMenu(false)}>
          {isExpanded ? (
            <Icon name="close32" className={styles['close-button']} />
          ) : (
            <MyAccount
              agentName={profile.contactName}
              agentCompany={profile.companyName}
              agentPortrait={profile.portraitId}
              isExpanded={isExpanded}
              showTooltip={false}
              toggleMenu={toggleMenu}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
