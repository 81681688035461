import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { buildClassName, getPccUrl } from 'components/common/utils/helpers';
import { IconButton } from '../../Button/IconButton/IconButton';
import { Link, useHistory } from 'react-router-dom';
import Icon from 'components/common/Icons';
import FeedbackModal from 'components/common/Feedback/FeedbackModal';
import { logoutOnServers } from 'components/common/utils/auth';
import './AppMenu.scss';
import { useStore } from 'components/store';
import MyAccount from 'components/common/MyAccount';

export interface Menu {
  isHidden?: boolean;
  isExpanded?: boolean;
  toggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AppMenu(props: Menu): JSX.Element {
  const { isHidden = false, isExpanded, toggleMenu } = props;
  const logout = useStore(state => state.logout);
  const className = buildClassName('AppMenu');
  const history = useHistory();
  const menuRef = useRef<HTMLDivElement>(null);
  const [user, isLoggedIn] = useStore(state => [state.user, state.isLoggedIn]);
  const [activeMenuItem, setActiveMenuItem] = useState(window.location.pathname);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [syncProfileStoreApi, profile] = useStore(state => [state.syncProfileStoreApi, state.profile]);

  const [isWiderThan1400, setIsWiderThan1400] = useState(window.innerWidth > 1400);
  const [isWiderThan600, setIsWiderThan600] = useState(window.innerWidth > 600);

  useLayoutEffect(() => {
    const handleResize = (): void => {
      setIsWiderThan1400(window.innerWidth > 1400);
      setIsWiderThan600(window.innerWidth > 600);
    };
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, [isWiderThan1400, isWiderThan600]);

  useEffect(() => {
    if (profile.accountId === 0) {
      syncProfileStoreApi();
    }
  }, [profile, syncProfileStoreApi]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setActiveMenuItem(window.location.pathname);
  });

  const routeTo = (routePath = ''): void => {
    history.push(routePath);
  };

  const completeLogOut = async (): Promise<void> => {
    logoutOnServers();
    logout();
  };

  const navigateToPcc = async (): Promise<void> => {
    const pccUrl = await getPccUrl(user, isLoggedIn);
    if (!pccUrl) {
      return;
    }

    window.location.assign(pccUrl);
  };

  /*Toggle between collapsed icon menu or full menu with icons and text
  Not applicable to >1401.  >1401 will always display the fully expanded menu*/
  const toggleDrawer = (bool: boolean): void => {
    if (!isWiderThan1400) {
      toggleMenu(bool);
    }
  };

  const handleButtonClick = (target: string): void => {
    routeTo(target);
    toggleDrawer(false);
  };

  //If user clicks outside of menu, collapse menu
  const handleOutsideClick = (e: MouseEvent): void => {
    //not applicable to mobile devices. Causes bugs without this check
    if (isWiderThan600) {
      if (menuRef.current != null) {
        if (menuRef && !menuRef.current.contains(e.target as Node)) {
          toggleDrawer(false);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', event => handleOutsideClick(event));

    return (): void => {
      window.removeEventListener('click', event => handleOutsideClick(event));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topMenuItems = [
    /* 
    Temporary changes to use listing manager as a default for home page.: 
      - commented out home icon :     { icon: 'home', target: '/', linkText: 'Home' },  
      - changing the target prop of listings to '/' instead of '/listing-manager'
    TODO: Revert these changes when working on  Feature 2402160 - MH 2.0 Dashboard Redesign [https://tfs.prd.costargroup.com/CoStarCollection/CoStar%20Land/_workitems/edit/2402160]
    */
    { icon: 'flag', target: '/', linkText: 'Listings' },
    { icon: 'message', target: '/leads', linkText: 'Leads' },
    { icon: 'stats', target: '/listing-stats', linkText: 'Analytics' },
    { icon: 'billing', target: '/billing-manager', linkText: 'Billing' },
    { icon: 'user', target: '/profile', linkText: 'My Profile' }
  ];

  return (
    <Fragment>
      {isExpanded && !isWiderThan600 && (
        <style
          dangerouslySetInnerHTML={{
            __html: `body { position: fixed; overflow: hidden;}`
          }}
        />
      )}
      <div className={`invisible-bar`}></div>
      <div
        ref={menuRef}
        className={`${className}${isHidden ? ' hidden' : ' display'} ${isExpanded ? ' expand' : ' collapse'}`}
        data-testid="shell-appmenu"
      >
        <div className="nav-links">
          <div className="logo-and-hamburger-container">
            <div role="button" tabIndex={0} onClick={(): void => toggleDrawer(!isExpanded)} className="logo-collapsed">
              <Icon name={'logoGreen'} />
            </div>
            <div role="button" tabIndex={0} onClick={(): void => toggleDrawer(!isExpanded)} className="full-logo">
              <Icon name={'logoGreenText'} />
            </div>
          </div>
          <div className="top-nav-link-section">
            {topMenuItems.map(({ icon, target, linkText }) => (
              <IconButton
                key={icon}
                icon={icon}
                onClick={(): void => handleButtonClick(target)}
                className={activeMenuItem === target ? 'active-nav' : 'inactive-nav'}
                toolTipText={linkText}
                isExpanded={isExpanded}
              >
                {linkText}
              </IconButton>
            ))}
          </div>
          <div className="bottom-nav-link-section">
            <IconButton
              key={'feedback'}
              icon={'feedback'}
              onClick={(): void => setIsFeedbackModalOpen(true)}
              className={isFeedbackModalOpen ? 'active-nav' : 'inactive-nav'}
              toolTipText={'Give Feedback'}
              isExpanded={isExpanded}
            >
              {'Give Feedback'}
            </IconButton>
            <Link to={{ pathname: 'https://network.land.com/resources/' }} target="_blank">
              <IconButton
                toolTipText={'Support'}
                key={'support'}
                icon={'support'}
                className={'bottom-nav-button'}
                isExpanded={isExpanded}
              >
                {'Support'}
              </IconButton>
            </Link>
            <div className={'swap-to-pcc-button'}>
              <IconButton
                toolTipText={'Switch to PCC'}
                key={'swap'}
                icon={'swap'}
                onClick={navigateToPcc}
                className={'bottom-nav-button'}
                isExpanded={isExpanded}
              >
                {'Switch to PCC'}
              </IconButton>
            </div>
            <IconButton
              toolTipText={'Log out'}
              key={'logOut'}
              icon={'logOut'}
              onClick={completeLogOut}
              className={'bottom-nav-button'}
              isExpanded={isExpanded}
            >
              {'Log out'}
            </IconButton>
          </div>
        </div>
        <div className="my-account-container">
          <MyAccount
            isExpanded={isWiderThan1400 || isExpanded}
            agentName={profile.contactName}
            agentCompany={profile.companyName}
            agentPortrait={profile.portraitId}
            showTooltip={true}
            toggleMenu={toggleMenu}
          />
        </div>
      </div>
      {isFeedbackModalOpen && (
        <FeedbackModal isExpanded={isFeedbackModalOpen} close={(): void => setIsFeedbackModalOpen(false)} />
      )}
    </Fragment>
  );
}
