import { Button } from 'components/common/Button/Button';
import { AddressResponse } from 'components/common/Form/PlacesAutocomplete';
import { INPUT_MODES, INPUT_TYPES } from 'components/common/Form/types/enums';
import ValidatedInput from 'components/common/Form/ValidatedInput';
import React, { useEffect, useState } from 'react';
import { fetchLandApiData } from 'components/common/utils/helpers';
import { useHistory } from 'react-router';
import { MemoryRouter } from 'react-router-dom';
import { ListingFormData, MaxLatitude, MaxLongitude, MinLatitude, MinLongitude } from '../defaultData';
import { ruleFactory } from '../../../common/Form/hooks/UseClientValidation';
import { firstSaveOfMapMarker } from 'components/common/Form/AddressInput/AddListingAddress';
import styles from './AddressEditGroup.module.scss';
import { ContextForm } from 'components/common/Form/ContextForm';
import SyncedTextInput from 'components/common/Form/SyncedInput/SyncedTextInput';
import SyncedInput from 'components/common/Form/SyncedInput';
import { CityInput } from 'components/common/Form/CityInput/CityInput';
import USStateSelect from 'components/common/Form/StateSelect/StateSelect';
import { useStore } from 'components/store';

interface AddressEditGroupType {
  address?: AddressResponse;
}

export default function AddressEditGroup(props: AddressEditGroupType): JSX.Element {
  const { address: addy } = props;
  const history = useHistory();
  const [address, setAddress] = useState(addy as AddressResponse);
  const setListingFlowState = useStore(state => state.setListingFlowState);

  const [selectedState, setSelectedState] = useState(address.stateId);

  useEffect((): void => {
    setSelectedState(Number(address.stateId));
  }, [address.stateId]);

  const mapMarker = {
    lat: address.latitude,
    lng: address.longitude
  } as unknown as google.maps.LatLngLiteral;

  const addListing = async (): Promise<void> => {
    if (document.getElementsByClassName('ccmp-error-message').length) {
      return;
    }

    const response = await fetchLandApiData<ListingFormData>('fetchAddress', '/Listing/add-listing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(address)
    });

    if (response.ok) {
      //1st save after Listing ID is Created; not save on move
      await firstSaveOfMapMarker(response.data as unknown as number, mapMarker);
      setListingFlowState(false);

      history.push(`/listing/edit/${response.data}`);
    } else {
      console.error(response.errors);
    }
  };

  return (
    <div className={styles['update-address-container']}>
      <ContextForm
        name="addressEdit"
        state={address}
        setState={setAddress}
        onSubmit={(): void => {
          const interval = setInterval((): void => {
            if (!window.verifyCityRequest) {
              clearInterval(interval);
              addListing();
            }
          }, 100);
        }}
      >
        <fieldset className={styles['address-edit-fieldset']}>
          <SyncedTextInput
            name="address"
            source="address"
            label="Address"
            placeholder="e.g. 123 main Street"
            maxLength={100}
            required
            rules={[ruleFactory.noUrl()]}
          />
          <USStateSelect required />
          <CityInput source="city" label="City" stateIdSource="stateId" selectedStateId={selectedState} required />
          <SyncedTextInput name="zip" source="zip" label="Zip" maxLength={10} required rules={[ruleFactory.isZip()]} />
          <SyncedInput
            source="latitude"
            render={(value, onBlur, onChange): JSX.Element => (
              <ValidatedInput
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                type={INPUT_TYPES.NUMBER}
                inputMode={INPUT_MODES.DECIMAL}
                label="Latitude"
                name="latitude"
                source="latitude"
                placeholder="Latitude"
                step="any"
                required
                rules={[ruleFactory.minValue(MinLatitude), ruleFactory.maxValue(MaxLatitude)]}
              />
            )}
          />
          <SyncedInput
            source="longitude"
            render={(value, onBlur, onChange): JSX.Element => (
              <ValidatedInput
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                type={INPUT_TYPES.NUMBER}
                label="Longitude"
                name="longitude"
                source="longitude"
                placeholder="Longitude"
                step="any"
                required
                rules={[ruleFactory.minValue(MinLongitude), ruleFactory.maxValue(MaxLongitude)]}
              />
            )}
          />
        </fieldset>
        <div className={styles['button-container']}>
          <MemoryRouter>
            <Button className={styles.button} type="submit">
              Save Address
            </Button>
          </MemoryRouter>
        </div>
      </ContextForm>
    </div>
  );
}
