import { StateName, States } from 'components/types/enums';
import SyncedSelect from '../SyncedInput/SyncedSelect';

interface Props {
  label?: string;
  placeholder?: string;
  required?: boolean;
  initialStateId?: States;
}

export default function USStateSelect(props: Props): JSX.Element {
  const stateList = Object.entries(StateName).map(item => {
    return { label: item[1], value: item[0] };
  });

  return (
    <SyncedSelect
      name="state"
      label={props.label || 'State'}
      source="stateId"
      placeholder={props.placeholder || 'Select a State'}
      options={stateList}
      required={props.required}
      value={props.initialStateId?.toString()}
    />
  );
}
