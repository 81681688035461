import { InputProps } from 'components/common/Form/types/interfaces';
import useSync from '../hooks/UseSync';
import { Ctx } from 'components/common/Form/FormDataProvider/FormDataProvider';
import { Fragment } from 'react';

export interface SyncedInputProps extends InputProps {
  source: string;
  render: (value: string, onBlur: InputProps['onBlur'], onChange: InputProps['onChange']) => JSX.Element;
}

export default function SyncedInput<T>(props: SyncedInputProps): JSX.Element {
  const { source, render } = props;
  const [value, setValue, syncValue] = useSync<string, T>(Ctx, source);
  const [, , syncFormMessage] = useSync<string, string>(Ctx, `UPDATE_MESSAGE`);

  return (
    <Fragment>
      {render(
        value,
        () => {
          const trimmedValue = typeof value === 'string' ? value?.trim() || '' : value;
          syncValue(trimmedValue);
          syncFormMessage(`${source} ${trimmedValue}`);
        },
        (e): void => {
          setValue(e.currentTarget.value);
        }
      )}
    </Fragment>
  );
}
