export interface ImageRequest {
  height?: number;
  width?: number;
  fit?: 'h' | 'w' | 'c' | 'l' | 'f';
  imageQuality?: number;
  brandId?: number;
  documentId: number;
}

export function imageRequestUrl({
  documentId,
  height = 0,
  width = 0,
  fit = 'c',
  imageQuality = 80,
  brandId = 2
}: ImageRequest): string {
  return `${process.env.REACT_APP_IMAGE_API_DOMAIN}/resizedimages/${height}/${width}/${fit}/${imageQuality}/${brandId}-${documentId}`;
}

/*
Image Fit:
h: height,
w: width,
c: contain,
l: cover,
f: fill
*/
