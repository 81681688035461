import SyncedInput from '../Form/SyncedInput';
import SyncedSelect from '../Form/SyncedInput/SyncedSelect';
import ValidatedTextArea from '../Form/ValidatedTextArea/ValidatedTextArea';
import { buildClassName } from '../utils/helpers';
import './Feedback.scss';
import RatingGroup from './RatingGroup';

export interface FeedbackData {
  comments?: string;
  email: string;
  type?: number;
  rating?: number;
  sourcePage: string;
}

const feedbackOptions = [
  { label: 'A Bug', id: 1 },
  { label: 'An Enhancement', id: 2 },
  { label: 'Kudos', id: 3 },
  { label: 'Needs Improvement', id: 4 },
  { label: 'Other', id: 5 }
];

interface FeedbackProps {
  onCommentChange?: (value: string) => void;
}

const Feedback = (props: FeedbackProps): JSX.Element => {
  const { onCommentChange } = props;
  const className = buildClassName('Feedback');

  return (
    <div className={className}>
      <span className="feedback-prompt">How would you rate your overall experience in Marketing Hub?</span>
      <RatingGroup source="rating" />
      <SyncedSelect
        options={feedbackOptions.map(option => ({ label: option.label, value: option.id.toString() }))}
        label="Type of Feedback"
        source="type"
        placeholder="Select an Option"
      />
      <SyncedInput
        source="comments"
        render={(value, onBlur, onChange): JSX.Element => (
          <ValidatedTextArea
            value={value}
            onBlur={onBlur}
            onChange={(e): void => {
              onChange && onChange(e);
              onCommentChange && onCommentChange(e.currentTarget.value);
            }}
            label="Comments"
            source="comments"
            placeholder="Tell us more..."
            className="comments-textarea"
          />
        )}
      />
    </div>
  );
};

export { Feedback, Feedback as default };
