import { Component, ErrorInfo, ReactNode } from 'react';
import { logToServer } from 'components/common/utils/helpers';
import { LogSeverity } from 'components/types/enums';

class ErrorBoundary extends Component<unknown> {
  constructor(props: unknown) {
    super(props);
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const message = `Error: ${error}\nReact Component: ${info.componentStack}`;
    logToServer(message, window.location.href, LogSeverity.CriticalYellow);

    if (window && window.location.href.indexOf('error') === -1) {
      window.history.replaceState(window.location.pathname, 'Error', `/error/?eurl=${window.location.pathname}`);
    }
  }

  render(): ReactNode {
    return this.props.children;
  }
}

export default ErrorBoundary;
