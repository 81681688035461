import { defaultFormState, DefaultFormStateType } from 'components/common/Form/types/types';
import { TargetedLandApiError } from '../../common/utils/helpers';
import { ACCOUNT_TYPES } from 'components/types/enums';

export interface TwilioLookupResult {
  errorMessage: string;
  dialerNumber: string; // dialer, e.g. +11234567890
  phoneNumber: string; // e.g. (123) 456-7890
  success: boolean;
}

// this will get replaced in another version with an obj that
//contains a list of cities as they type in MH 1.1 or higher
export interface CityLookupResults {
  cityName: string;
  cityId?: number | undefined;
  countyId: number | undefined;
  countyName: string;
  stateName: string;
}

interface ProfileCompanyAddress {
  companyAddress1: string;
  companyAddress2: string;
  companyCity: string;
  companyState: string;
  companyZip: string;
}

interface ProfileBillingAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

interface ProfileAddress extends ProfileBillingAddress, ProfileCompanyAddress {
  accountId: number;
}

export interface ProfileContactInfo {
  accountId: number;
  email: string;
  phoneCell: string; // mobile number as national format, e.g. '(123) 456-7890'
  dialerCell: string; // mobile number dialer format, e.g. '+11234567890'
  smsNotifications: boolean;
  phone: string;
  dialerPhone: string;
  phoneToll: string;
  dialerToll: string;
  fax: string;
  dialerFax: string;
  trackingPhoneNumber: string;
  dialerTrackingPhone: string;
}

export interface Attachment {
  documentId: number;
  filename: string;
}

export interface MediaInfo {
  mediaUrl: string;
  mediaTypeId: number;
  providerId: number;
}

export interface ProfileSellerPageInfo {
  accountId: number;
  url: string;
  description: string;
  documents: Attachment[];
  media: {
    Video: MediaInfo;
    Linkedin: MediaInfo;
    Instagram: MediaInfo;
    Facebook: MediaInfo;
    X: MediaInfo;
  };
}

export interface Profile
  extends ProfileContactInfo,
    ProfileBillingAddress,
    ProfileCompanyAddress,
    ProfileSellerPageInfo {
  // Profile Pictures
  portraitId: number;
  logoId: number;

  // section BasicInfo
  contactName: string;
  companyName: string;
  licenseNumber: string;
  password: string;

  // section AccountDetails
  accountLevel: string;
  accountType: ACCOUNT_TYPES;
  parentAccountType: ACCOUNT_TYPES;
  sellerTypeId: number;
  sellerRepEmail: string;
  sellerRepPhone: string;
  sellerRepName: string;
  salesCompFullAccess: boolean;

  //section sellerPage
  // section MetaData
  accountId: number;
  errors: TargetedLandApiError[];
  status: number;
}

export interface Dimensions {
  height: number;
  width: number;
}

export const initialProfileData: Profile = {
  logoId: 0,
  portraitId: 0,

  // section BasicInfo
  contactName: '',
  companyName: '',
  licenseNumber: '',
  password: '',

  // section AccountDetails
  accountLevel: '',
  accountType: ACCOUNT_TYPES.STANDARD_FREE_SELLER,
  parentAccountType: ACCOUNT_TYPES.STANDARD_FREE_SELLER,
  sellerTypeId: 0,
  sellerRepEmail: '',
  sellerRepPhone: '',
  sellerRepName: '',
  salesCompFullAccess: false,

  // section ContactInfo
  email: '',
  phoneCell: '',
  dialerCell: '',
  smsNotifications: false,
  phone: '',
  dialerPhone: '',
  phoneToll: '',
  dialerToll: '',
  fax: '',
  dialerFax: '',
  trackingPhoneNumber: '',
  dialerTrackingPhone: '',

  // section BillingAddress
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',

  // section CompanyAddress
  companyAddress1: '',
  companyAddress2: '',
  companyCity: '',
  companyState: '',
  companyZip: '',

  url: '',
  description: '',
  documents: [],
  media: {
    Video: { mediaUrl: '', mediaTypeId: 0, providerId: 0 },
    Linkedin: { mediaUrl: '', mediaTypeId: 0, providerId: 0 },
    Instagram: { mediaUrl: '', mediaTypeId: 0, providerId: 0 },
    Facebook: { mediaUrl: '', mediaTypeId: 0, providerId: 0 },
    X: { mediaUrl: '', mediaTypeId: 0, providerId: 0 }
  },

  // section MetaData
  accountId: 0,
  errors: [] as TargetedLandApiError[],
  status: 200
};

export const initialProfileFormData = defaultFormState(initialProfileData);
export type ProfileFormData = DefaultFormStateType<Profile>;
export type ProfileAddressForm = DefaultFormStateType<ProfileAddress>;
export type ProfileContactInfoForm = DefaultFormStateType<ProfileContactInfo>;
export type ProfileSellerPageForm = DefaultFormStateType<ProfileSellerPageInfo>;
export type MyAccountForm = DefaultFormStateType<Partial<Profile>>;
