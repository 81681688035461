import React, { SetStateAction } from 'react';
import Form, { FormProps } from '../Form';
import FormDataProvider from '../FormDataProvider/FormDataProvider';

interface ContextFormProps<T> extends FormProps {
  state: T;
  setState: React.Dispatch<SetStateAction<T>>;
}

function ContextForm<T>({ state, setState, ...formProps }: ContextFormProps<T>): JSX.Element {
  return (
    <FormDataProvider state={state} setState={setState}>
      <Form {...formProps} />
    </FormDataProvider>
  );
}

export { ContextForm, ContextForm as default };
