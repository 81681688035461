import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Feedback, { FeedbackData } from '.';
import { ContextForm } from '../Form/ContextForm';
import { defaultFormState } from '../Form/types/types';
import Heading from '../Heading/Heading';
import Modal, { ModalProps } from '../Modal';
import CommonModalButtons from '../Modal/CommonModalButtons';
import { buildClassName, fetchLandApiData, TargetedLandApiError } from '../utils/helpers';
import './FeedbackModal.scss';
import Sent from 'assets/svg/sent.svg';
import Button from '../Button/Button';
import { useStore } from 'components/store';

const initialFeedbackData = defaultFormState({
  comments: '',
  type: 0,
  rating: 0
} as FeedbackData);

const FeedbackModal = (props: ModalProps): JSX.Element => {
  const { isExpanded, close } = props;
  const [isOpen, setIsOpen] = useState(isExpanded);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [feedback, setFeedback] = useState({ ...initialFeedbackData });
  const user = useStore(state => state.user);

  const [formState, setFormState] = useState({ errors: [] as TargetedLandApiError[], status: 0 });

  useEffect(() => {
    setIsOpen(isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    const shouldDisable =
      (!feedback.comments && !feedback.type && !feedback.rating) || feedback.ERROR_STATE().CLIENT_ERRORS.length > 0;
    setIsSubmitDisabled(shouldDisable);
  }, [feedback]);

  const saveFeedback = async (): Promise<void> => {
    setIsSubmitting(true);

    feedback.email = user?.email || '';
    feedback.sourcePage = window.location.href;
    feedback.type = feedback.type || 0;

    const response = await fetchLandApiData<boolean>('submitFeedback', '/feedback', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(feedback)
    });

    setFormState({ errors: response.errors, status: response.status });

    setIsSubmitting(false);
  };

  const handleClose = useCallback((): void => {
    setIsOpen(false);
    setFormState({ errors: [], status: 0 });
    setFeedback({ ...initialFeedbackData });
    close && close();
  }, [close]);

  const handleCommentChange = useCallback(
    (value: string): void => {
      value = value?.trim() || '';
      if (isSubmitDisabled && value) {
        setIsSubmitDisabled(false);
      } else if (!isSubmitDisabled && !value) {
        setIsSubmitDisabled(true);
      }
    },
    [isSubmitDisabled]
  );

  const className = buildClassName('FeedbackModal');

  const renderContentByStatus = (): JSX.Element => {
    switch (formState.status) {
      case 200:
        return (
          <div className="success-container">
            <span>Thank you!</span>
            <img src={Sent} alt="sent" />
            <Heading level="1">Your feedback helps us make Marketing Hub better.</Heading>
          </div>
        );

      case 401:
        // unauthorized
        return <Redirect to="/" />;

      default:
        return <Feedback onCommentChange={handleCommentChange} />;
    }
  };

  return (
    <Modal
      title="Leave Feedback"
      className={className + ' modal-container-plain-with-heading'}
      close={handleClose}
      isExpanded={isOpen}
      popupOnly
      footer={
        formState.status !== 200 ? (
          <CommonModalButtons
            submitLabel="Submit"
            disableSubmit={isSubmitDisabled || isSubmitting}
            formName="feedbackForm"
            onCancel={handleClose}
          />
        ) : (
          <div className={'bottom-modal-buttons'}>
            <Button onClick={handleClose} size="med" kind="primary">
              Close
            </Button>
          </div>
        )
      }
    >
      <ContextForm name="feedbackForm" state={feedback} setState={setFeedback} onSubmit={saveFeedback}>
        {renderContentByStatus()}
      </ContextForm>
      {formState.errors?.length > 0 && (
        <div className="modal-error">
          {formState.errors[0].messages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      )}
    </Modal>
  );
};

export { FeedbackModal, FeedbackModal as default };
