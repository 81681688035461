import { create } from 'zustand';
import { createAuthSlice } from './AuthStore';
import { createGlobalStoreSlice } from './GlobalStore';
import { createProfileSlice } from './ProfileStore.ts';
import { createListingManagerSlice } from './ListingManagerStore';
import { createLeadsSlice } from './LeadsStore';
import { AuthSlice, GlobalStoreSlice, ProfileSlice, ListingManagerSlice, LeadsSlice } from './StoreSlices';

export const useStore = create<AuthSlice & ProfileSlice & GlobalStoreSlice & ListingManagerSlice & LeadsSlice>()(
  (...args) => ({
    ...createAuthSlice(...args),
    ...createProfileSlice(...args),
    ...createGlobalStoreSlice(...args),
    ...createListingManagerSlice(...args),
    ...createLeadsSlice(...args)
  })
);
