import { ListingLevel, MarketStatus } from './enums';

export interface LinkData {
  description: string;
  link: string;
}

export interface PhotoEditorState {
  isEditorOpen: boolean;
  documentId: number;
  label: string;
  type: PhotoType;
  isEditMode: boolean;
  height: number;
  width: number;
}

export enum PhotoType {
  None,
  Listing,
  Portrait,
  Logo,
  Thumbnail
}

export interface ImageInfo {
  documentId: number;
  height: number;
  label: string;
  width: number;
}

export const emptyEditorState: PhotoEditorState = {
  documentId: 0,
  height: 0,
  isEditMode: false,
  isEditorOpen: false,
  label: '',
  type: PhotoType.None,
  width: 0
};

export interface DeleteModalConfirmationState {
  photoType: PhotoType;
  isOpen: boolean;
  imgSrc: string;
}

export const emptyDeleteModalState: DeleteModalConfirmationState = {
  isOpen: false,
  photoType: PhotoType.None,
  imgSrc: ''
};

export interface CropPostBody {
  CropXRatio: number;
  CropYRatio: number;
  CropHeightRatio: number;
  CropWidthRatio: number;
}

export interface Listing {
  acres: number;
  address: string;
  baths: number;
  beds: number;
  county?: string;
  exposure: number;
  hasDraft: boolean;
  homeSqft: number;
  isDiamond: boolean;
  isGold: boolean;
  isPlatinum: boolean;
  isPublished?: boolean;
  listingDate: string;
  listingId: number;
  listingIdForLaf: number;
  listingIdForLw: number;
  listingLevelId: ListingLevel;
  price: number;
  status: MarketStatus;
  thumbnailId: number;
  title: string;
  totalLeads: number;
}

export interface Lead {
  callDuration: number;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  currentIndex: number;
  emailMessage: string;
  isGeneralInquiry: boolean;
  leadDate: string;
  leadDateDisplay: string;
  leadId: number;
  leadType: string;
  listingAddress: string;
  listingCity: string;
  listingCounty: string;
  listingId: number;
  listingState: string;
  listingTitle: string;
  listingZip: string;
  recordingId: string;
  recordingUrl: string;
}

export interface ListingsStatusSummary {
  activeListingCount: number;
  draftListingCount: number;
  inactiveListingCount: number;
  soldListingCount: number;
}

export interface ListingsView {
  listings: Listing[];
  nextLink: string;
  pageIndex: number;
  linkData: LinkData[];
  prevLink: string;
  statusSummary: ListingsStatusSummary;
  totalRows: number;
}

export const fullImageCropPostBody = { CropHeightRatio: 1, CropWidthRatio: 1, CropXRatio: 0, CropYRatio: 0 };

export interface DropdownDates {
  startDate: Date;
  endDate: Date;
}
