import { imageRequestUrl } from 'components/common/utils/imageUrlGenerator';
import { PhotoType } from 'components/types/interfaces';
import { Crop } from 'react-image-crop';
import { Dimensions } from './interface';

const thumbnailHeight = 40;
const thumbnailWidth = 40;
const logoHeight = 70;
const portraitHeight = 150;
const portraitWidth = 150;

export const displayImgSrc = (documentId: number, type: PhotoType): string => {
  switch (type) {
    case PhotoType.Thumbnail:
      return imageRequestUrl({ documentId, height: thumbnailHeight, width: thumbnailWidth });
    case PhotoType.Logo:
      return imageRequestUrl({ documentId, height: logoHeight, width: 0, fit: 'h' });
    case PhotoType.Portrait:
      return imageRequestUrl({ documentId, height: portraitHeight, width: portraitWidth });
    case PhotoType.Listing:
    default:
      return imageRequestUrl({ documentId, height: 400, width: 500, fit: 'h' });
  }
};

export const editImgSrc = (documentId: number, type: PhotoType, dimensions: Dimensions | undefined): string => {
  if (dimensions != undefined && dimensions.height > 0 && dimensions.width > 0) {
    return imageRequestUrl({ documentId, height: dimensions.height, width: dimensions.width });
  }
  return displayImgSrc(documentId, type);
};

export const getInitialCrop = (type: PhotoType): Crop => {
  switch (type) {
    case PhotoType.Portrait:
      return {
        unit: 'px',
        height: portraitHeight,
        width: portraitWidth,
        x: 0,
        y: 0,
        aspect: portraitWidth / portraitHeight
      };
    case PhotoType.Listing:
    default:
      return { unit: '%', height: 100, width: 100, x: 0, y: 0 };
  }
};

export function validateImageSize(
  height: number | undefined,
  width: number | undefined,
  recommendedImgHeight: number,
  recommendedImgWidth: number
): boolean {
  return !!(height && height >= recommendedImgHeight && width && width >= recommendedImgWidth);
}
