import React from 'react';
import { buildClassName } from '../utils/helpers';
import './Heading.scss';

export const HeadingLevels = ['1', '2', '3', '4', '5', 'r'];

export interface HeadingProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  level?: typeof HeadingLevels[number];
}

export function Heading(props: HeadingProps): JSX.Element {
  const { level = '1', children, className, id } = props;

  let cssClasses = buildClassName('Heading', HeadingLevels, level);
  if (className) cssClasses += ` ${className}`;

  const hLevel = level === 'r' ? '1' : level;

  return React.createElement(`h${hLevel}`, { className: cssClasses, id }, children);
}

export default Heading;
