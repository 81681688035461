import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { AllStoreSlices, GlobalStoreSlice } from '../StoreSlices';

export const createGlobalStoreSlice: StateCreator<
  AllStoreSlices,
  [['zustand/immer', never]],
  [['zustand/immer', never]],
  GlobalStoreSlice
> = immer(set => ({
  isPrevRouteInternal: false,
  isListingFlowActive: false,
  setListingFlowState: (listingFlowState: boolean): void =>
    set(state => {
      state.isListingFlowActive = listingFlowState;
    }),
  setIsPrevRouteInternal: (isInternal: boolean): void =>
    set(state => {
      state.isPrevRouteInternal = isInternal;
    })
}));
