import { BrowserRouter as Router } from 'react-router-dom';
import AppShell from 'components/common/AppShell/AppShell';

export default function AppRouter(): JSX.Element {  
  return (
    <Router>
      <AppShell/>
    </Router>
  );
}
