import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { AllStoreSlices, ProfileSlice } from '../StoreSlices';
import { fetchLandApiData, getProfileAccountLevelFromApiResponse } from 'components/common/utils/helpers';
import { Profile, initialProfileFormData } from 'components/pages/Profile/interface';

export const createProfileSlice: StateCreator<
  AllStoreSlices,
  [['zustand/immer', never]],
  [['zustand/immer', never]],
  ProfileSlice
> = immer(set => ({
  profile: initialProfileFormData,
  syncProfileStoreApi: (): void => {
    fetchLandApiData<typeof initialProfileFormData>('getProfile', '/profile').then(res => {
      set(state => {
        const accountLevel = getProfileAccountLevelFromApiResponse(res);

        state.profile = { ...res.data, accountLevel };
      });
    });
  },
  syncProfileStoreForm: (profile: Profile): void => {
    set(state => {
      state.profile = profile;
    });
  }
}));
