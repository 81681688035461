import React, { Fragment } from 'react';

interface Props {
  errors: string[];
}

export const ValidationMessage = (props: Props): JSX.Element => {
  const hasErrors = props.errors.length > 0;
  return (
    <Fragment>
      {hasErrors
        ? props.errors.map(message => (
            <span className={'ccmp-error-message'} key={message}>
              {message}
            </span>
          ))
        : null}
    </Fragment>
  );
};
