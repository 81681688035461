import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { AllStoreSlices, ListingManagerSlice } from '../StoreSlices';
import { MarketStatus } from 'components/types/enums';

const InitialModalState = { open: false, listingId: '', status: MarketStatus.Undefined, displayErrors: [] as string[] };

export const createListingManagerSlice: StateCreator<
  AllStoreSlices,
  [['zustand/immer', never]],
  [['zustand/immer', never]],
  ListingManagerSlice
> = immer(set => ({
  modalState: InitialModalState,
  setModalState: (open: boolean, listingId: string, status: MarketStatus): void =>
    set(state => {
      state.modalState = { open: open, listingId: listingId, status: status, displayErrors: [] };
    }),
  resetModalState: (): void =>
    set(state => {
      state.modalState = InitialModalState;
    })
}));
