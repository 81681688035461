import React from 'react';
import { ChildProps, Testable } from '../Form/types/interfaces';
import { buildClassName, createQaAttribute } from '../utils/helpers';
import Icon from '../Icons';

import './Button.scss';

export interface ButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement>, Testable {
  onClick?: (e: React.FormEvent<HTMLButtonElement>) => void;
  size?: 'lg' | 'med' | 'sm';
  kind?: 'primary' | 'secondary' | 'tertiary' | 'text' | 'square' | 'link' | 'destructive';
  isDisabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  form?: string;
  icon?: string;
}
export interface IconButtonType extends ChildProps, ButtonType {
  icon?: string;
}

export const buttonKinds = ['primary', 'secondary', 'tertiary', 'text', 'square', 'link', 'destructive'];
export const buttonSizes = ['lg', 'med', 'sm'];

export function Button(props: IconButtonType): JSX.Element {
  const { testId, isDisabled = false, ...propsToPass } = props;
  const { onClick, children, icon } = propsToPass;
  const { id = '', size = 'med', kind = 'primary' } = propsToPass;
  const { className: additionalClasses } = propsToPass;

  let className = buildClassName('Button', buttonKinds, kind);
  className = buildClassName('Button', buttonSizes, size, className);
  className += additionalClasses;

  const handleClick = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  const qaId = id ? id : testId;
  const qaAttribute = qaId && createQaAttribute(qaId);
  return (
    <button
      disabled={isDisabled}
      type="button"
      {...propsToPass}
      className={className}
      onClick={handleClick}
      data-testid={testId}
      {...qaAttribute}
    >
      {icon && <Icon name={icon} />}
      {children}
    </button>
  );
}

export default Button;
