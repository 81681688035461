import Button from '../Button/Button';
import useSync from '../Form/hooks/UseSync';
import Icon from '../Icons';
import { Ctx } from 'components/common/Form/FormDataProvider/FormDataProvider';
import { buildClassName } from '../utils/helpers';

import './RatingGroup.scss';

interface RatingGroupProps {
  source: string;
}

const ratingOptions = [
  { label: 'Needs Work', icon: 'feedbackBad', id: 1 },
  { label: 'Good', icon: 'feedbackGood', id: 2 },
  { label: 'Excellent', icon: 'feedbackExcellent', id: 3 }
];

const RatingGroup = (props: RatingGroupProps): JSX.Element => {
  const { source } = props;
  const [rating, setRating, syncRating] = useSync<number, number>(Ctx, source);

  const className = buildClassName('RatingGroup');

  const changeRating = (id: number): void => {
    setRating(id);
    syncRating(id);
  };

  return (
    <div className={className}>
      {ratingOptions.map(option => (
        <Button
          key={option.id}
          className={'rating-option' + (option.id === rating ? ' selected' : '')}
          kind="tertiary"
          onClick={(): void => changeRating(option.id)}
        >
          <div className="icon-container">
            <div className={'selected-icon-overlay'} />
            <Icon name={option.icon} />
          </div>
          <span>{option.label}</span>
        </Button>
      ))}
    </div>
  );
};

export { RatingGroup, RatingGroup as default };
