import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { LoadableComponent } from '@loadable/component';
import Login from '../../pages/Login';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { useHistory } from 'react-router-dom';
import { getLoggedInUserState } from '../utils/auth';
import { useStore } from 'components/store';
import { LoggedInStatus } from 'components/store/interfaces';

interface Props {
  Component: LoadableComponent<unknown>;
  children?: ReactNode;
  exact: boolean;
  path: string;
  privateRoute: boolean;
}

export default function PrivateRoute({ Component, children, exact, path, privateRoute, ...rest }: Props): JSX.Element {
  const [isLoggedIn, loginState, login, logout] = useStore(state => [
    state.isLoggedIn,
    state.loginState,
    state.login,
    state.logout
  ]);
  const history = useHistory();
  const [authStatusKnown, setAuthStatusKnown] = useState(false);
  const getLoggedInState = useCallback(async (): Promise<void> => {
    const nullableUserInfo = await getLoggedInUserState(() => {
      history.push('/');
    });

    if (nullableUserInfo === null) {
      logout();
    } else {
      login(nullableUserInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, login, logout]);

  useEffect(() => {
    if (!authStatusKnown) {
      getLoggedInState();
      setAuthStatusKnown(true);
    }
  }, [authStatusKnown, getLoggedInState]);

  return (
    <Route
      {...rest}
      exact={exact}
      path={path}
      render={(props): ReactNode => {
        // if we are logged in (to api and mh servers) or this is a non private route, render the requested component
        if (isLoggedIn || !privateRoute) {
          return (
            <ErrorBoundary>
              <Component {...props}>{children}</Component>
            </ErrorBoundary>
          );
        }

        // if we are not logged in, render the login page
        if (loginState.status === LoggedInStatus.loggedOut) {
          return <Login />;
        }
        // otherwise, we are waiting for login status from the api
        return null;
      }}
    />
  );
}
