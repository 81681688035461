import { TargetedLandApiError } from '../../common/utils/helpers';
import { defaultFormState } from '../../common/Form/types/types';
import Modal from 'components/common/Modal';
import { ruleFactory } from 'components/common/Form/hooks/UseClientValidation';
import { InputHtmlType } from 'components/common/Form/types/interfaces';
import FormDataProvider from 'components/common/Form/FormDataProvider/FormDataProvider';
import Form from 'components/common/Form';
import { Button } from '../../common/Button/Button';
import ValidatedInput from '../../common/Form/ValidatedInput';
import { INPUT_MODES, INPUT_TYPES } from '../../common/Form/types/enums';
import SyncedInput from '../../common/Form/SyncedInput';
import { Dispatch, FormEvent, KeyboardEvent, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { LoginService, PasswordRequestResetData } from './LoginService';

import styles from './login.module.scss';


export interface ForgotPasswordModalProps {
  openState: boolean;
  setOpenState: Dispatch<SetStateAction<boolean>>;
}

export const initialPasswordResetData: PasswordRequestResetData = {
  errors: [],
  resetEmail: '',
  status: 0
};

export const initialResetFormData = defaultFormState(initialPasswordResetData);

export function ForgotPasswordModal({ openState = false, setOpenState }: ForgotPasswordModalProps): React.ReactElement {
  const resetEmailRef = useRef<HTMLInputElement>(null);
  const [resetFormData, setResetFormData] = useState(initialResetFormData);
  const [resetRequestErrors, setResetRequestErrors] = useState({} as Record<string, TargetedLandApiError[]>);
  const [allowRequests, setAllowRequests] = useState(false);

  useEffect((): void => {
    if (!openState) setResetFormData(initialResetFormData);
  }, [openState]);

  useEffect((): void => {
    const errors = resetFormData.ERROR_STATE();
    if (errors.CLIENT_ERRORS.length !== 0) {
      setAllowRequests(false);
    }
  }, [resetFormData]);

  const closeForgotPassword = useCallback( (): void => {
    setOpenState(false);
  },[setOpenState]);

  const requestReset = async (e: FormEvent): Promise<void> => {
    if (!allowRequests) return;

    const payload: string = resetFormData.resetEmail;

    setAllowRequests(false);

    try {
      const response = await LoginService.postResetRequest(payload);
      if (!response.data.success) {
        setAllowRequests(true);
      } else if (response) {
        setResetRequestErrors(prevState => ({ ...prevState, errors: response.errors }));
        setResetFormData(formData => {
          return {
            ...formData,
            errors: response?.errors,
            response
          };
        });
      }
    } catch (err) {
      setResetFormData(formData => ({
        ...formData,
        errors: [{ messages: ['Request failed'], target: '' }]
      }));
    }
    e.preventDefault();
  };
  return (
    <Modal
      title={'Forgot Password'}
      className="modal-container-plain-with-heading"
      close={closeForgotPassword}
      isExpanded={openState}
      popupOnly
      footer={
        <div className={'bottom-modal-buttons'}>
          {resetFormData?.response?.success ? (
            <Button onClick={closeForgotPassword} size="med" kind="primary" testId="close-after-success-button">
              Close
            </Button>
          ) : (
            <Button
              onClick={requestReset}
              isDisabled={!allowRequests}
              size="med"
              kind="primary"
              testId="close-after-success-button"
            >
              Reset Password
            </Button>
          )}
        </div>
      }
    >
      <div className="input-container">
        <FormDataProvider state={resetFormData} setState={setResetFormData}>
          <Form name="ResetPassword" onSubmit={requestReset}>
            <p>Enter your Email address and we will send you a link to reset your password.</p>
            <SyncedInput
              source={'resetEmail'}
              render={(value, onBlur, onChange): JSX.Element => (
                <ValidatedInput
                  autoFocus={true}
                  ref={resetEmailRef}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e: FormEvent<InputHtmlType>): void => {
                    setAllowRequests(
                      e.currentTarget.value.length > 0 || resetFormData?.CLIENT_ERRORS['resetEmail']?.length === 0
                    );
                    onChange && onChange(e);
                  }}
                  submitOnEnter={true}
                  onKeyDown={(e: KeyboardEvent<InputHtmlType>): void => {
                    if (e.key === 'Enter') {
                      onBlur && onBlur(e);
                    }
                  }}
                  type={INPUT_TYPES.EMAIL}
                  inputMode={INPUT_MODES.TEXT}
                  placeholder="Your Email address"
                  label="Email"
                  name="resetEmail"
                  source="resetEmail"
                  rules={[
                    ruleFactory.required('You must enter your e-mail address.'),
                    ruleFactory.emailFormat('Invalid Email Address. Please make sure that the email address you entered is in correct format.')
                  ]}
                />
              )}
            />
          </Form>
        </FormDataProvider>
        {resetFormData?.response?.success === false && (
          <div className="modal-error">
            <div>
              <br />
              {resetFormData.response.message}
            </div>
          </div>
        )}
        {resetFormData?.response?.success && (
          <div className={styles['modal-success']}>
            <div>
              <br />
              <p>If there is an account with that email we have sent you a link to reset your password.</p>
            </div>
          </div>
        )}
        {resetRequestErrors.errors?.length > 0 && (
          <div className="modal-error">
            {resetRequestErrors?.errors[0].messages.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </div>
        )}
        <br />
      </div>
    </Modal>
  );
}

