import useSync from 'components/common/Form/hooks/UseSync';
import { Ctx } from 'components/common/Form/FormDataProvider/FormDataProvider';
import { ValidatedSelectInput, ValidatedSelectInputProps } from '../ValidatedSelectInput';
interface SyncedSelectProps extends ValidatedSelectInputProps {
  source: string;
}

export default function SyncedSelect<T>(props: SyncedSelectProps): JSX.Element {
  const { options, source, defaultVal } = props;

  const [val, setVal, syncVal] = useSync<string, T>(Ctx, source);
  const [, , syncFormMessage] = useSync<string, string>(Ctx, `UPDATE_MESSAGE`);

  return (
    <ValidatedSelectInput
      {...props}
      value={val || ''}
      defaultVal={defaultVal || options.find(option => option.value === val)?.value}
      onBlur={(e): void => {
        const { value } = e.target as HTMLSelectElement;
        syncVal(value);
        syncFormMessage(`${source} ${value}`);
      }}
      onChange={(e): void => {
        const { value } = e.currentTarget;
        setVal(value);
        syncVal(value);
        syncFormMessage(`${source} ${value}`);
      }}
    ></ValidatedSelectInput>
  );
}
