export enum CreditCardTypes {
  Visa = 1,
  MasterCard = 2,
  Amex = 3,
  Discover = 4
}

export const CreditCardName: { [key: number]: string } = {
  [CreditCardTypes.Visa]: 'ccVisaLogo',
  [CreditCardTypes.MasterCard]: 'ccMasterCardLogo',
  [CreditCardTypes.Amex]: 'ccAmexLogo',
  [CreditCardTypes.Discover]: 'ccDiscoverLogo'
};

export enum PaymentTransactionType {
  Charge = 0,
  Void = 1,
  Refund = 2
}

export enum SiteIds {
  LAND = 0,
  TEXAS = 48,
  LANDWATCH = 1113
}

export enum LogSeverity {
  Information = 0,
  Verbose = 1,
  Debug = 2,
  Warning = 3,
  Error = 4,
  CriticalYellow = 5,
  CriticalOrange = 6,
  CriticalRedOrange = 7,
  CriticalRed = 8
}

export enum ReadStatus {
  NOTREAD = 10,
  READ = 20
}

export enum States {
  Alabama = 1,
  Alaska = 2,
  Arizona = 4,
  Arkansas = 5,
  California = 6,
  Colorado = 8,
  Connecticut = 9,
  Delaware = 10,
  DistrictofColumbia = 11,
  Florida = 12,
  Georgia = 13,
  Hawaii = 15,
  Idaho = 16,
  Illinois = 17,
  Indiana = 18,
  Iowa = 19,
  Kansas = 20,
  Kentucky = 21,
  Louisiana = 22,
  Maine = 23,
  Maryland = 24,
  Massachusetts = 25,
  Michigan = 26,
  Minnesota = 27,
  Mississippi = 28,
  Missouri = 29,
  Montana = 30,
  Nebraska = 31,
  Nevada = 32,
  NewHampshire = 33,
  NewJersey = 34,
  NewMexico = 35,
  NewYork = 36,
  NorthCarolina = 37,
  NorthDakota = 38,
  Ohio = 39,
  Oklahoma = 40,
  Oregon = 41,
  Pennsylvania = 42,
  RhodeIsland = 44,
  SouthCarolina = 45,
  SouthDakota = 46,
  Tennessee = 47,
  Texas = 48,
  Utah = 49,
  Vermont = 50,
  Virginia = 51,
  Washington = 53,
  WestVirginia = 54,
  Wisconsin = 55,
  Wyoming = 56
}

export const StateCode: { [key: number]: string } = {
  [States.Alabama]: 'AL',
  [States.Alaska]: 'AK',
  [States.Arizona]: 'AZ',
  [States.Arkansas]: 'AR',
  [States.California]: 'CA',
  [States.Colorado]: 'CO',
  [States.Connecticut]: 'CT',
  [States.Delaware]: 'DE',
  [States.DistrictofColumbia]: 'DC',
  [States.Florida]: 'FL',
  [States.Georgia]: 'GA',
  [States.Hawaii]: 'HI',
  [States.Idaho]: 'ID',
  [States.Illinois]: 'IL',
  [States.Indiana]: 'IN',
  [States.Iowa]: 'IA',
  [States.Kansas]: 'KS',
  [States.Kentucky]: 'KY',
  [States.Louisiana]: 'LA',
  [States.Maine]: 'ME',
  [States.Maryland]: 'MD',
  [States.Massachusetts]: 'MA',
  [States.Michigan]: 'MI',
  [States.Minnesota]: 'MN',
  [States.Mississippi]: 'MS',
  [States.Missouri]: 'MO',
  [States.Montana]: 'MT',
  [States.Nebraska]: 'NE',
  [States.Nevada]: 'NV',
  [States.NewHampshire]: 'NH',
  [States.NewJersey]: 'NJ',
  [States.NewMexico]: 'NM',
  [States.NewYork]: 'NY',
  [States.NorthCarolina]: 'NC',
  [States.NorthDakota]: 'ND',
  [States.Ohio]: 'OH',
  [States.Oklahoma]: 'OK',
  [States.Oregon]: 'OR',
  [States.Pennsylvania]: 'PA',
  [States.RhodeIsland]: 'RI',
  [States.SouthCarolina]: 'SC',
  [States.SouthDakota]: 'SD',
  [States.Tennessee]: 'TN',
  [States.Texas]: 'TX',
  [States.Utah]: 'UT',
  [States.Vermont]: 'VT',
  [States.Virginia]: 'VA',
  [States.Washington]: 'WA',
  [States.WestVirginia]: 'WV',
  [States.Wisconsin]: 'WI',
  [States.Wyoming]: 'WY'
};

export const StateName: { [key: number]: string } = {
  [States.Alabama]: 'Alabama',
  [States.Alaska]: 'Alaska',
  [States.Arizona]: 'Arizona',
  [States.Arkansas]: 'Arkansas',
  [States.California]: 'California',
  [States.Colorado]: 'Colorado',
  [States.Connecticut]: 'Connecticut',
  [States.Delaware]: 'Delaware',
  [States.DistrictofColumbia]: 'District of Columbia',
  [States.Florida]: 'Florida',
  [States.Georgia]: 'Georgia',
  [States.Hawaii]: 'Hawaii',
  [States.Idaho]: 'Idaho',
  [States.Illinois]: 'Illinois',
  [States.Indiana]: 'Indiana',
  [States.Iowa]: 'Iowa',
  [States.Kansas]: 'Kansas',
  [States.Kentucky]: 'Kentucky',
  [States.Louisiana]: 'Louisiana',
  [States.Maine]: 'Maine',
  [States.Maryland]: 'Maryland',
  [States.Massachusetts]: 'Massachusetts',
  [States.Michigan]: 'Michigan',
  [States.Minnesota]: 'Minnesota',
  [States.Mississippi]: 'Mississippi',
  [States.Missouri]: 'Missouri',
  [States.Montana]: 'Montana',
  [States.Nebraska]: 'Nebraska',
  [States.Nevada]: 'Nevada',
  [States.NewHampshire]: 'New Hampshire',
  [States.NewJersey]: 'New Jersey',
  [States.NewMexico]: 'New Mexico',
  [States.NewYork]: 'New York',
  [States.NorthCarolina]: 'North Carolina',
  [States.NorthDakota]: 'North Dakota',
  [States.Ohio]: 'Ohio',
  [States.Oklahoma]: 'Oklahoma',
  [States.Oregon]: 'Oregon',
  [States.Pennsylvania]: 'Pennsylvania',
  [States.RhodeIsland]: 'Rhode Island',
  [States.SouthCarolina]: 'South Carolina',
  [States.SouthDakota]: 'South Dakota',
  [States.Tennessee]: 'Tennessee',
  [States.Texas]: 'Texas',
  [States.Utah]: 'Utah',
  [States.Vermont]: 'Vermont',
  [States.Virginia]: 'Virginia',
  [States.Washington]: 'Washington',
  [States.WestVirginia]: 'West Virginia',
  [States.Wisconsin]: 'Wisconsin',
  [States.Wyoming]: 'Wyoming'
};

export enum PRICE {
  CALL_FOR_PRICE = 1,
  AUCTION = 2
}

export enum ListingLevel {
  Free = 3,
  Basic = 20,
  Premium = 23,
  Signature = 30
}

export const ListingLevelLabels = {
  [ListingLevel.Free]: '',
  [ListingLevel.Basic]: 'Standard',
  [ListingLevel.Premium]: 'Premium',
  [ListingLevel.Signature]: 'Signature'
};

//This need to be the same as Data/MarketStatus.cs
export enum MarketStatus {
  Undefined,
  Active = 1,
  Pending,
  OffMarket,
  Sold,
  Unpublished,
  Deleted
}

export const MarketStatusLabels = {
  [MarketStatus.Undefined]: '',
  [MarketStatus.Active]: 'For Sale',
  [MarketStatus.OffMarket]: 'Off Market',
  [MarketStatus.Pending]: 'Pending',
  [MarketStatus.Sold]: 'Sold',
  [MarketStatus.Unpublished]: 'Draft',
  [MarketStatus.Deleted]: 'Deleted'
};

export const MarketStatusClassNames = {
  [MarketStatus.Undefined]: '',
  [MarketStatus.Active]: 'active',
  [MarketStatus.Pending]: 'pending',
  [MarketStatus.OffMarket]: 'off-market',
  [MarketStatus.Sold]: 'sold',
  [MarketStatus.Unpublished]: 'draft',
  [MarketStatus.Deleted]: 'deleted'
};

export enum ListingFilterTypes {
  Active = 'active',
  OffMarket = 'inactive',
  Sold = 'sold',
  Draft = 'draft',
  All = 'all'
}

export interface ListingFilters {
  param: ListingFilterTypes;
}

export enum ListingAnalyticsFilterTypes {
  Exposures = 'Exposures',
  Actions = 'Actions',
  Leads = 'Leads',
  Interactions = 'Interactions'
}

export enum DatesOptions {
  SevenDays = 7,
  ThirtyDays = 30,
  NinetyDays = 90,
  Year = 365
}

export const DatesDropdownLabels: { [key in DatesOptions]: string } = {
  [DatesOptions.SevenDays]: '7 Days',
  [DatesOptions.ThirtyDays]: '30 Days',
  [DatesOptions.NinetyDays]: '90 Days',
  [DatesOptions.Year]: 'Year'
};

export const DatesDropdownOptions = [
  { key: DatesOptions.SevenDays, label: '7 Days' },
  { key: DatesOptions.ThirtyDays, label: '30 Days' },
  { key: DatesOptions.NinetyDays, label: '90 Days' },
  { key: DatesOptions.Year, label: 'Year' }
];

//AG Chart types
export enum SERIES_TYPES {
  Pie = 'pie',
  Column = 'column'
}

export enum ACCOUNT_TYPES {
  STANDARD_BUYER = 1,
  CORPORATE_STANDARD_MASTER = 3,
  CORPORATE_CHILD = 4,
  LISTHUB_SELLER = 8,
  LOOPNET_SELLER = 9,
  COURTESY_SELLER = 10,
  STANDARD_FREE_SELLER = 11,
  UNIVERSAL_STANDARD_SELLER = 12,
  UNIVERSAL_PREMIUM_SELLER = 13,
  UNIVERSAL_SIGNATURE_SELLER = 40,
  CORPORATE_SIGNATURE_MASTER = 41,
  LEGACY_PREMIUM_SELLER = 50,
  CORPORATE_PREMIUM_MASTER = 51
}

export enum ACCOUNT_TYPE_TIER_LABELS {
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
  SIGNATURE = 'Signature',
  LISTHUB = 'ListHub',
  FREE = 'Free'
}

export const ACCOUNT_TYPE_TIER = {
  [ACCOUNT_TYPES.STANDARD_BUYER]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.CORPORATE_STANDARD_MASTER]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.CORPORATE_CHILD]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.LISTHUB_SELLER]: ACCOUNT_TYPE_TIER_LABELS.LISTHUB,
  [ACCOUNT_TYPES.LOOPNET_SELLER]: ACCOUNT_TYPE_TIER_LABELS.FREE,
  [ACCOUNT_TYPES.COURTESY_SELLER]: ACCOUNT_TYPE_TIER_LABELS.FREE,
  [ACCOUNT_TYPES.STANDARD_FREE_SELLER]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.UNIVERSAL_STANDARD_SELLER]: ACCOUNT_TYPE_TIER_LABELS.STANDARD,
  [ACCOUNT_TYPES.UNIVERSAL_PREMIUM_SELLER]: ACCOUNT_TYPE_TIER_LABELS.PREMIUM,
  [ACCOUNT_TYPES.UNIVERSAL_SIGNATURE_SELLER]: ACCOUNT_TYPE_TIER_LABELS.SIGNATURE,
  [ACCOUNT_TYPES.CORPORATE_SIGNATURE_MASTER]: ACCOUNT_TYPE_TIER_LABELS.SIGNATURE,
  [ACCOUNT_TYPES.LEGACY_PREMIUM_SELLER]: ACCOUNT_TYPE_TIER_LABELS.PREMIUM,
  [ACCOUNT_TYPES.CORPORATE_PREMIUM_MASTER]: ACCOUNT_TYPE_TIER_LABELS.PREMIUM
};
