import React, { Fragment, useEffect, useState } from 'react';
import { ChildProps } from '../types/interfaces';
import styles from './TextAreaField.module.scss';

export interface TextAreaFieldProps extends ChildProps {
  id?: string;
  rows?: string;
  cols?: string;
  label?: string;
  maxLength?: number;
  name?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  invalid?: boolean;
  onBlur?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
}

export function TextAreaField({
  id,
  name,
  maxLength,
  value,
  label,
  placeholder,
  required,
  invalid,
  onBlur,
  onChange
}: TextAreaFieldProps): JSX.Element {
  const [val, setVal] = useState(value || '');

  useEffect(() => {
    setVal(value || '');
  }, [value]);

  const handleBlur = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (onChange) {
      onChange(e);
    }

    setVal(e.target.value);
  };

  id = id || label;
  return (
    <Fragment>
      {label && <label htmlFor={id}>{label + (required ? ' *' : '')}</label>}
      <div className={`${styles['textarea-div']} ${invalid ? styles['error-input-field'] : ''}`}>
        <textarea
          id={id}
          name={name}
          maxLength={maxLength}
          value={(val as string) || ''}
          placeholder={placeholder}
          aria-required={required}
          aria-invalid={invalid}
          onChange={handleChange}
          onBlur={handleBlur}
          data-qa={`${id}-textarea`}
          data-testid={`${id}-textarea`}
        />
      </div>
    </Fragment>
  );
}

export default TextAreaField;
