import { AuthenticationInfo } from 'components/store/interfaces';
import { fetchApiData, LandApiResponse } from 'components/common/utils/helpers';
import { PasswordRequestResetData } from 'components/pages/Login/LoginService';
import { apiDomain } from '../utils/apiDomainHelper';

const ApiIsLoggedInUrl = apiDomain + '/authenticate/isloggedin';
const MHIsLoggedInUrl = '/mhauthenticate/isloggedin';
const ApiTokenUrl = apiDomain + '/authenticate/logintoken';
const MHTokenUrl = '/mhauthenticate/logintoken';
const ApiLogin = apiDomain + '/authenticate/login';
const MHLogin = '/mhauthenticate/login';
const ApiLogoutUrl = apiDomain + '/authenticate/logout';
const MHLogoutUrl = '/mhauthenticate/logout';
const ApiLoginViaTokenUrl = apiDomain + '/authenticate/loginviatoken';
const MHLoginViaTokenUrl = '/mhauthenticate/loginviatoken';
const GetLoginInfoUrl = apiDomain + '/authenticate/logininfo';
const PasswordResetRequestUrl = apiDomain + '/authenticate/requestreset';

export const getApiIsLoggedIn = async (): Promise<boolean> => {
  const response = await fetchApiData<string>('isLoggedIn API', ApiIsLoggedInUrl);
  return !!(response.ok && response.data);
};

export const getMHIsLoggedIn = async (): Promise<boolean> => {
  const response = await fetchApiData<string>('isLoggedIn MH', MHIsLoggedInUrl);
  return !!(response.ok && response.data);
};

export const getApiToken = async (): Promise<LandApiResponse<string>> => {
  const response = await fetchApiData<string>('api loginToken', ApiTokenUrl);
  return response;
};

export const getMHToken = async (): Promise<LandApiResponse<string>> => {
  const response = await fetchApiData<string>('mh loginToken', MHTokenUrl);
  return response;
};

export const postApiLogin = async (credentials: FormData): Promise<LandApiResponse<AuthenticationInfo>> => {
  const config = { method: 'POST', body: credentials };
  return fetchApiData<AuthenticationInfo>('apiLogin', ApiLogin, config);
};

export const postMHLogin = async (credentials: FormData): Promise<LandApiResponse<AuthenticationInfo>> => {
  const config = { method: 'POST', body: credentials };
  return fetchApiData<AuthenticationInfo>('mhLogin', MHLogin, config);
};

export const postApiLogout = async (): Promise<LandApiResponse<AuthenticationInfo>> => {
  const config = { method: 'POST' };
  return fetchApiData<AuthenticationInfo>('apiLogout', ApiLogoutUrl, config);
};

export const postMHLogout = async (): Promise<LandApiResponse<AuthenticationInfo>> => {
  const config = { method: 'POST' };
  return fetchApiData<AuthenticationInfo>('mhLogout', MHLogoutUrl, config);
};

export const postApiLoginViaToken = async (token: string): Promise<LandApiResponse<AuthenticationInfo>> => {
  return await fetchApiData<AuthenticationInfo>('apiLoginViaToken', `${ApiLoginViaTokenUrl}/${token}`, {
    method: 'POST'
  });
};

export const postMHLoginViaToken = async (token: string): Promise<LandApiResponse<AuthenticationInfo>> => {
  return await fetchApiData<AuthenticationInfo>('apiLoginViaToken', `${MHLoginViaTokenUrl}/${token}`, {
    method: 'POST'
  });
};

export const getLoginInfo = async (): Promise<LandApiResponse<AuthenticationInfo>> => {
  return await fetchApiData<AuthenticationInfo>('userInfo', GetLoginInfoUrl);
};

export const postPasswordResetRequest = async (payload: string): Promise<LandApiResponse<PasswordRequestResetData>> => {
  return await fetchApiData<PasswordRequestResetData>('Password Reset Request', PasswordResetRequestUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  });
};
