import { Fragment, useEffect, useState } from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import routes from 'routing';
import { useStore } from 'components/store';
import { UnregisterCallback } from 'history';
import PrivateRoute from '../PrivateRoute';
import AppHeader from './AppHeader';
import AppMenu from './AppMenu';
import './AppShell.scss';

const noAppShellRoutes: string[] = [];
routes.forEach(route => {
  if (route.hideAppShell) {
    noAppShellRoutes.push(route.path);
  }
});

let unListen: UnregisterCallback;
export default function AppShell(): JSX.Element {
  const [getIsLoginValid, isPrevRouteInternal, setIsPrevRouteInternal] = useStore(state => [
    state.getIsLoginValid,
    state.isPrevRouteInternal,
    state.setIsPrevRouteInternal
  ]);

  const routeMatch = useRouteMatch({ path: noAppShellRoutes, exact: true, strict: true });
  const hideAppShell = !getIsLoginValid() || routeMatch;
  const [displayMenu, setDisplayMenu] = useState(false);

  const history = useHistory();

  useEffect(() => {
    unListen = history.listen(() => {
      setIsPrevRouteInternal(true);
    });
  }, [history, setIsPrevRouteInternal]);

  useEffect(() => {
    if (isPrevRouteInternal) {
      unListen();
    }
  }, [isPrevRouteInternal]);

  return (
    <Fragment>
      {!hideAppShell && (
        <AppMenu
          isHidden={hideAppShell || displayMenu === false}
          isExpanded={displayMenu}
          toggleMenu={setDisplayMenu}
        />
      )}
      <div className="route-container" data-testid="shell-routecontainer">
        {!hideAppShell && <AppHeader toggleMenu={setDisplayMenu} isExpanded={displayMenu} />}
        <Switch>
          {routes.map(({ path, loadable, exact, privateRoute }) => (
            <PrivateRoute
              key={path}
              exact={exact}
              path={path}
              privateRoute={privateRoute}
              Component={loadable}
            ></PrivateRoute>
          ))}
        </Switch>
      </div>
    </Fragment>
  );
}
