import { ImgHTMLAttributes } from 'react';
import { buildClassName, logoAltText } from '../utils/helpers';

import AddIcon from 'assets/svg/add-icon.svg';
import AddListing from 'assets/svg/icons-add-listing.svg';
import ArrowBack from 'assets/svg/arrow-back.svg';
import ArrowBackLand from 'assets/svg/arrow-back-land-blue.svg';
import ArrowDownFull from 'assets/svg/icons-dark-arrow-down-full.svg';
import ArrowUpFull from 'assets/svg/icons-dark-arrow-up-full.svg';
import Attachment from 'assets/svg/icons-blue-attachment.svg';
import AttachmentBlueSpruce from 'assets/svg/icons-blue-spruce-attachment.svg';
import Avatar from 'assets/svg/avatar.svg';
import Aviation from 'assets/svg/icons-activities-aviation.svg';
import Beach from 'assets/svg/icons-activities-beach.svg';
import BetaMHLogoDark from 'assets/svg/beta-mh-logo-dark.svg';
import BetaMHLogoDarkMobile from 'assets/svg/beta-mh-logo-dark-mobile.svg';
import BetaMHLogoWhite from 'assets/svg/beta-mh-logo-white.svg';
import Binoculars from 'assets/svg/icons-dark-binoculars-filled.svg';
import Billing from 'assets/svg/icons-billing.svg';
import Boating from 'assets/svg/icons-activities-boating.svg';
import Calendar from 'assets/svg/icons-calendar.svg';
import CalendarDark from 'assets/svg/icons-dark-calendar.svg';
import Camping from 'assets/svg/icons-activities-camping.svg';
import Canoeingkayaking from 'assets/svg/icons-activities-canoeingkayaking.svg';
import CcAmexLogo from 'assets/AmexCC.png';
import CcDebit from 'assets/svg/cc-debit-icon.svg';
import CcDebitAdd from 'assets/svg/add-cc-icon.svg';
import CcDiscoverLogo from 'assets/DiscoverCC.png';
import CcMasterCardLogo from 'assets/MasterCardCC.png';
import CcVisaLogo from 'assets/VisaCC.png';
import Chatbox from 'assets/svg/icons-dark-chatbox.svg';
import CheckLandBlue from 'assets/svg/check_32_land_blue.svg';
import ChevronDown from 'assets/svg/icons-dark-chevron-down.svg';
import ChevronLeft from 'assets/svg/icons-dark-chevron-left.svg';
import ChevronRight from 'assets/svg/icons-dark-chevron-right.svg';
import ChevronRight24 from 'assets/svg/chevron-right-24.svg';
import ChevronUp from 'assets/svg/icons-dark-chevron-up.svg';
import Close from 'assets/svg/icons-dark-close.svg';
import Close32 from 'assets/svg/close_32.svg';
import CloseWhite from 'assets/svg/icons-white-close.svg';
import Conservation from 'assets/svg/icons-activities-conservation.svg';
import CoverBadge from 'assets/svg/misc-cover-badge.svg';
import Crop from 'assets/svg/icons-crop-placeholder.svg';
import DeleteIcon from 'assets/svg/icons-delete.svg';
import DeleteWhite from 'assets/svg/icons-white-delete.svg';
import Destructive from 'assets/svg/destructive.svg';
import DigiCertSeal from 'assets/seal-digicert.png';
import Document from 'assets/svg/icons-dark-documents.svg';
import Dollars from 'assets/svg/icons-dark-dollars.svg';
import Download from 'assets/svg/icons-download.svg';
import Edit from 'assets/svg/icons-edit.svg';
import EditWhite from 'assets/svg/icons-white-edit.svg';
import Ellipsis from 'assets/ellipsis.png';
import EmailLead from 'assets/svg/icons-email-lead.svg';
import EmailSuccess from 'assets/svg/icons-email-success.svg';
import Expand from 'assets/svg/icons-dark-expand.svg';
import Expanded from 'assets/svg/icons-expanded.svg';
import Facebook from 'assets/svg/icons-gray-facebook.svg';
import Feedback from 'assets/svg/icons-feedback.svg';
import FeedbackBad from 'assets/svg/icons-feedback-bad.svg';
import FeedbackExcellent from 'assets/svg/icons-feedback-excellent.svg';
import FeedbackGood from 'assets/svg/icons-feedback-good.svg';
import Fishing from 'assets/svg/icons-activities-fishing.svg';
import Flag from 'assets/svg/icons-flag.svg';
import Hamburger from 'assets/svg/icons-menu-hamburger.svg';
import Hide from 'assets/svg/icons-dark-hide.svg';
import Home from 'assets/svg/icons-home.svg';
import Horsebackriding from 'assets/svg/icons-activities-horsebackriding.svg';
import Hunting from 'assets/svg/icons-activities-hunting.svg';
import Instagram from 'assets/svg/icons-gray-instagram.svg';
import LineGraph from 'assets/svg/icon-line-graph.svg';
import Linkedin from 'assets/svg/icons-gray-linkedin.svg';
import List from 'assets/svg/list.svg';
import ListGreen from 'assets/svg/icons-green-list-view.svg';
import ListView from 'assets/svg/icons-list-view.svg';
import Lock from 'assets/svg/icons-lock.svg';
import Logo from 'assets/svg/icons-logo.svg';
import LogoGreen from 'assets/svg/icons-logo-green.svg';
import LogoGreenText from 'assets/svg/icons-logo-text-green.svg';
import LogOut from 'assets/svg/icons-log-out.svg';
import Menu from 'assets/svg/icons-dark-menu.svg';
import Message from 'assets/svg/icons-message.svg';
import MhLogo from 'assets/svg/mh-logo.svg';
import ModalBackArrow from 'assets/svg/modal-back-arrow.svg';
import NoPhoto from 'assets/svg/no-photo.svg';
import Offroading from 'assets/svg/icons-activities-offroading.svg';
import PayNow from 'assets/svg/icons-pay-now.svg';
import Phone from 'assets/svg/icons-dark-pone.svg';
import ProfilePortrait from 'assets/svg/profile-portrait-icon.svg';
import Ranch from 'assets/svg/ranch.svg';
import Redo from 'assets/svg/icons-dark-undo-redo-icon.svg';
import RotateLeft from 'assets/svg/icons-dark-rotate-left.svg';
import RotateRight from 'assets/svg/icons-dark-rotate-right.svg';
import Rving from 'assets/svg/icons-activities-rving.svg';
import Search from 'assets/svg/icons-dark-search.svg';
import Show from 'assets/svg/icons-dark-show.svg';
import Star from 'assets/svg/icons-dark-star.svg';
import Stats from 'assets/svg/icons-stats.svg';
import Support from 'assets/svg/icons-support.svg';
import Swap from 'assets/svg/icons-swap.svg';
import TwitterX from 'assets/svg/icons-gray-twitter-x.svg';
import Upload from 'assets/svg/icons-upload.svg';
import User from 'assets/svg/icons-user.svg';
import UserWhite from 'assets/svg/icons-white-user.svg';

// define icon name, with its svg path and alt text
export const Icons: Record<string, Record<string, string>> = {
  add: { path: AddIcon, alt: 'add' },
  addListing: { path: AddListing, alt: 'add-listing' },
  arrowBack: { path: ArrowBack, alt: 'arrow-back' },
  arrowBackLand: { path: ArrowBackLand, alt: 'arrow-back-land' },
  arrowDownFull: { path: ArrowDownFull, alt: 'dark-arrow-down-full' },
  arrowUpFull: { path: ArrowUpFull, alt: 'dark-arrow-up-full' },
  attachment: { path: Attachment, alt: 'attachment' },
  attachmentBlueSpruce: { path: AttachmentBlueSpruce, alt: 'attachment-blue-spruce' },
  avatar: { path: Avatar, alt: 'Avatar' },
  aviation: { path: Aviation, alt: 'activities-aviation' },
  backArrow: { path: ModalBackArrow, alt: 'modal-back-arrow' },
  beach: { path: Beach, alt: 'activities-beach' },
  betaMHLogoDark: { path: BetaMHLogoDark, alt: logoAltText },
  betaMHLogoDarkMobile: { path: BetaMHLogoDarkMobile, alt: logoAltText },
  betaMHLogoWhite: { path: BetaMHLogoWhite, alt: logoAltText },
  billing: { path: Billing, alt: 'billing' },
  binoculars: { path: Binoculars, alt: 'binoculars' },
  boating: { path: Boating, alt: 'activities-boating' },
  calendar: { path: Calendar, alt: 'calendar' },
  calendarDark: { path: CalendarDark, alt: 'dark-calendar' },
  camping: { path: Camping, alt: 'activities-camping' },
  canoeingkayaking: { path: Canoeingkayaking, alt: 'activities-canoeingkayaking' },
  chatbox: { path: Chatbox, alt: 'chatbox' },
  checkLandBlue: { path: CheckLandBlue, alt: 'check-land-blue' },
  chevronDown: { path: ChevronDown, alt: 'dark-chevron-down' },
  chevronLeft: { path: ChevronLeft, alt: 'dark-chevron-left' },
  chevronRight: { path: ChevronRight, alt: 'dark-chevron-right' },
  chevronRight24: { path: ChevronRight24, alt: 'chevron-right-24' },
  chevronUp: { path: ChevronUp, alt: 'dark-chevron-up' },
  close: { path: Close, alt: 'dark-close' },
  close32: { path: Close32, alt: 'close' },
  conservation: { path: Conservation, alt: 'activities-conservation' },
  coverBadge: { path: CoverBadge, alt: 'isc-cover-badge' },
  crop: { path: Crop, alt: 'crop-image' },
  ccAmexLogo: { path: CcAmexLogo, alt: 'amex' },
  ccDebit: { path: CcDebit, alt: 'cc-debit' },
  ccDebitAdd: { path: CcDebitAdd, alt: 'add-cc' },
  ccDiscoverLogo: { path: CcDiscoverLogo, alt: 'disc' },
  ccMasterCardLogo: { path: CcMasterCardLogo, alt: 'mc' },
  ccVisaLogo: { path: CcVisaLogo, alt: 'visa' },
  deleteIcon: { path: DeleteIcon, alt: 'delete' },
  destructive: { path: Destructive, alt: 'destructive' },
  digiCertSeal: { path: DigiCertSeal, alt: 'seal-digicert' },
  document: { path: Document, alt: 'document' },
  dollars: { path: Dollars, alt: 'dark-dollars' },
  download: { path: Download, alt: 'download' },
  edit: { path: Edit, alt: 'edit' },
  emailLead: { path: EmailLead, alt: 'email-lead' },
  emailSuccess: { path: EmailSuccess, alt: 'email-success' },
  ellipsis: { path: Ellipsis, alt: 'elip' },
  expand: { path: Expand, alt: 'dark-expand' },
  expanded: { path: Expanded, alt: 'expanded' },
  facebook: { path: Facebook, alt: 'facebook' },
  feedback: { path: Feedback, alt: 'feedback' },
  feedbackBad: { path: FeedbackBad, alt: 'feedback-bad' },
  feedbackExcellent: { path: FeedbackExcellent, alt: 'feedback-excellent' },
  feedbackGood: { path: FeedbackGood, alt: 'feedback-good' },
  fishing: { path: Fishing, alt: 'activities-fishing' },
  flag: { path: Flag, alt: 'flag' },
  greenList: { path: ListGreen, alt: 'green-list-view' },
  hamburger: { path: Hamburger, alt: 'hamburger-menu' },
  hide: { path: Hide, alt: 'hide' },
  home: { path: Home, alt: 'home' },
  horsebackriding: { path: Horsebackriding, alt: 'activities-horseback' },
  hunting: { path: Hunting, alt: 'activities-hunting' },
  instagram: { path: Instagram, alt: 'instagram' },
  lineGraph: { path: LineGraph, alt: 'line-graph' },
  linkedin: { path: Linkedin, alt: 'linkedin' },
  list: { path: List, alt: 'List' },
  listView: { path: ListView, alt: 'list-view' },
  lock: { path: Lock, alt: 'Private use' },
  logo: { path: Logo, alt: 'Land.com - Marketing Hub' },
  logoGreen: { path: LogoGreen, alt: 'Land.com - Marketing Hub' },
  logoGreenText: { path: LogoGreenText, alt: 'Land.com - Marketing Hub' },
  logOut: { path: LogOut, alt: 'log-out' },
  menu: { path: Menu, alt: 'dark-menu' },
  message: { path: Message, alt: 'Message' },
  mhLogo: { path: MhLogo, alt: 'MarketingHub Logo' },
  noPhoto: { path: NoPhoto, alt: 'no-photo' },
  offroading: { path: Offroading, alt: 'activities-offroading' },
  paynow: { path: PayNow, alt: 'pay-now' },
  phone: { path: Phone, alt: 'dark-pone' },
  profilePortrait: { path: ProfilePortrait, alt: 'profile-portrait' },
  ranch: { path: Ranch, alt: 'ranch' },
  redo: { path: Redo, alt: 'dark-undo-redo-icon' },
  rotateLeft: { path: RotateLeft, alt: 'dark-rotate-left' },
  rotateRight: { path: RotateRight, alt: 'dark-rotate-right' },
  rving: { path: Rving, alt: 'activities-rving' },
  search: { path: Search, alt: 'dark-search' },
  show: { path: Show, alt: 'show' },
  star: { path: Star, alt: 'dark-star' },
  stats: { path: Stats, alt: 'stats' },
  swap: { path: Swap, alt: 'swap' },
  support: { path: Support, alt: 'support' },
  twitterX: { path: TwitterX, alt: 'twitter-x' },
  upload: { path: Upload, alt: 'upload' },
  user: { path: User, alt: 'user' },
  whiteClose: { path: CloseWhite, alt: 'white-close' },
  whiteDelete: { path: DeleteWhite, alt: 'white-delete' },
  whiteEdit: { path: EditWhite, alt: 'white-edit' },
  whiteUser: { path: UserWhite, alt: 'white-user' }
};

export interface IconType {
  name: string;
  path?: string;
  alt?: string;
  className?: string;
  imgProps?: ImgHTMLAttributes<HTMLImageElement>;
}

export default function Icon(props: IconType): JSX.Element {
  const classNameOptions = props.className ? [props.className] : [];
  const className = buildClassName('Icon', classNameOptions) + (props.className || '');
  const { name = '', path = '', alt = '' } = props;
  const icon = Icons[name];

  if (!icon) return <i />;

  const iconPath = path || icon.path;
  const altText = alt || icon.alt;

  return <i className={className}>{iconPath && <img src={iconPath} alt={altText} {...props.imgProps} />}</i>;
}
