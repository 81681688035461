import { FormEvent, Fragment, useContext } from 'react';
import { ValidationContext } from './FormDataProvider/FormDataProvider';
import { enforceStrictValidation } from './hooks/UseClientValidation';
import { ChildProps } from './types/interfaces';
import { ValidationState } from './types/types';

type FormType = 'Container' | 'Sibling';

export interface FormProps extends ChildProps {
  name: string;

  // Nested forms can cause problems (double submission, etc.), so to prevent this from happening use formType = 'Sibling'.
  // The form name must also be provided to the submitting element to link it to the form when this is done.
  formType?: FormType;
  allowValidationBypass?: boolean;
  onSubmit?: (event: FormEvent<HTMLFormElement>, validationState: ValidationState) => void;
}

function Form({
  name,
  formType = 'Container',
  children,
  allowValidationBypass,
  onSubmit = (e): void => e.preventDefault()
}: FormProps): JSX.Element {
  const validationState = useContext(ValidationContext);
  const { validators, pendingValidations } = validationState;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    // Wait for all pending validations first. Because validations are async, they may still be on-going
    // if a field was very recently modified.
    await Promise.all(Object.values(pendingValidations));

    // When an attempt to submit the form is made, we'll evaluate all registered form validators
    // and only execute the submit action if all pass.
    const result = (await enforceStrictValidation(validators)).every(value => value);
    if (result || allowValidationBypass) {
      onSubmit && onSubmit(e, validationState);
    }
  };

  return formType === 'Container' ? (
    <form name={name} id={name} onSubmit={handleSubmit} className="form" data-testid={name}>
      {children}
    </form>
  ) : (
    <Fragment>
      {children}

      {/* When the form is a sibling, the form name must be provided to the submitting input / button. */}
      <form name={name} id={name} onSubmit={handleSubmit} className="form" data-testid={name} />
    </Fragment>
  );
}

export { Form, Form as default };
