import { MarketStatus } from 'components/types/enums';

// commented items are properties defined in server-side BasicUserInfo
export interface BasicUserInfo {
  canManageBilling: boolean;
  canManageLandsConnectors: boolean;
  canManageUsers: boolean;
  canUseMH: boolean;
  canUsePCC: boolean;
  contactName: string;
  email: string;
  id: number;
  isActive: boolean;
  isAdmin: boolean;
  isBlocked: boolean;
  isFree: boolean;
  isFromFeed: boolean;
  isLandsInsight: boolean;
  isPremium: boolean;
  isSeller: boolean;
  isSignature: boolean;
  phone: string;
  type: string;
  trackingPhoneNumber: string;
}

// used to initialize NewAuthenticationInfo, below
export const NewUserInfo: BasicUserInfo = {
  canManageBilling: false,
  canManageLandsConnectors: false,
  canManageUsers: false,
  canUseMH: false,
  canUsePCC: false,
  contactName: '',
  email: '',
  id: 0,
  isActive: false,
  isAdmin: false,
  isBlocked: false,
  isFree: true,
  isFromFeed: false,
  isLandsInsight: false,
  isPremium: false,
  isSeller: false,
  isSignature: false,
  phone: '',
  type: '',
  trackingPhoneNumber: ''
};
export const LoggedInExpiration = 5;

export enum LoggedInStatus {
  unknown,
  loggedIn,
  loggedOut
}

// the format we get from the server
export interface AuthenticationInfo {
  isLoggedIn: boolean;
  message: string;
  redirect: string;
  userInfo: BasicUserInfo;
}

export interface LoginState {
  status: LoggedInStatus;
  user: BasicUserInfo;
  expires: Date;
}

// used as default return value from login fetch requests
export const NewAuthenticationInfo: AuthenticationInfo = {
  isLoggedIn: false,
  message: '',
  redirect: '',
  userInfo: NewUserInfo
};

export interface ModalState {
  open: boolean;
  listingId: string;
  status: MarketStatus;
  displayErrors: string[];
}
