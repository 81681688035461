import useSync from 'components/common/Form/hooks/UseSync';
import React, { useRef, useEffect } from 'react';
import { ContextValue, Ctx, useCtx } from 'components/common/Form/FormDataProvider/FormDataProvider';
import { DefaultFormStateType } from 'components/common/Form/types/types';
import { fetchLandApiData } from 'components/common/utils/helpers';

//https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
export interface Place {
  place: google.maps.places.PlaceResult;
}

export interface AddressResponse {
  address: string;
  address2?: string;
  city: string;
  cityId?: number;
  state: string;
  zip: string;
  stateId?: number;
  county?: string;
  countyId?: number;
  latitude?: number;
  longitude?: number;
}

// function to retrieve Land ready values to address shape
export interface AutoCompleteProps {
  isMapApiLoaded: boolean;
  onUpdate?: (addressResponse: AddressResponse) => void;
}

export default function PlacesAutocomplete<T extends Place>(props: AutoCompleteProps): JSX.Element {
  const autoCompleteRef = useRef<HTMLInputElement>();
  const [provided] = useCtx() as ContextValue<DefaultFormStateType<T>>;
  const [, , syncVal] = useSync<string, DefaultFormStateType<T>>(Ctx, 'place');
  const [, , syncFormMessage] = useSync<string, string>(Ctx, `UPDATE_MESSAGE`);
  const { isMapApiLoaded, onUpdate } = props;

  useEffect(() => {
    if (autoCompleteRef && isMapApiLoaded) {
      const autocomplete = new google.maps.places.Autocomplete(
        (autoCompleteRef as React.MutableRefObject<HTMLInputElement>).current
      ) as google.maps.places.Autocomplete;

      autocomplete.setFields(['address_components', 'adr_address', 'formatted_address', 'geometry', 'name']);
      autocomplete.setComponentRestrictions({
        country: ['us']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        syncVal(place);
        syncFormMessage(`place + ${place.place_id}`);
        googleAddressLookup(place, onUpdate);
      });

      bindSelectToEnterKey((autoCompleteRef as React.MutableRefObject<HTMLInputElement>).current);
    }
  }, [isMapApiLoaded, syncVal, onUpdate, syncFormMessage]);

  return (
    <div className="ccmp-places-autocomplete">
      <label htmlFor={'places-autocomplete'}>Address</label>
      <input
        id={'places-autocomplete'}
        type="text"
        className="input-field field-100"
        defaultValue={provided?.place?.formatted_address}
        ref={autoCompleteRef as React.RefObject<HTMLInputElement>}
      ></input>
    </div>
  );
}

const googleAddressLookup = async (
  val: google.maps.places.PlaceResult,
  onUpdate: ((addressResponse: AddressResponse) => void) | undefined
): Promise<void> => {
  const response = await fetchLandApiData<AddressResponse>('fetchAddress', '/listing/read-address', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(val)
  });

  if (response.ok) {
    onUpdate && onUpdate(response.data);
  } else {
    console.error(response.errors);
  }
};

function bindSelectToEnterKey(input: HTMLInputElement): void {
  const origListener = input.addEventListener;
  const arrowDownEvt = new KeyboardEvent('keydown', {
    key: 'ArrowDown',
    code: 'ArrowDown',
    keyCode: 40
  });

  const origListenerWrapper = (type: string, listener: unknown): void => {
    if (type === 'keydown') {
      const itemsListener = listener;
      listener = (event: KeyboardEvent): void => {
        const selected = document.getElementsByClassName('pac-item-selected').length;
        if (event.key === 'Enter' && !selected) {
          (itemsListener as (e: KeyboardEvent) => void).apply(input, [arrowDownEvt]);
        }
        (itemsListener as (e: KeyboardEvent) => void).apply(input, [event]);
      };
    }
    origListener.apply(input, [type, listener as any]); // eslint-disable-line
  };
  input.addEventListener = origListenerWrapper;
}
