import { postPasswordResetRequest } from 'components/common/utils/authRequestHandler';
import { LandApiResponse, TargetedLandApiError } from '../../common/utils/helpers';

export interface ResetRequestResponse {
  ok?: boolean;
  status?: number;
  statusText?: string;
  success?: false;
  message?: string;
}

export interface PasswordRequestResetData {
  errors?: TargetedLandApiError[];
  response?: ResetRequestResponse;
  success?: boolean;
  resetEmail: string;
  status: number;
}

export async function postResetRequest(payload: string): Promise<LandApiResponse<PasswordRequestResetData>> {
  LoginService.hasPendingCall = true;
  const response = postPasswordResetRequest(payload);
  LoginService.hasPendingCall = false;
  return response;
}

export const LoginService = {
  hasPendingCall: false,
  postResetRequest,
}
