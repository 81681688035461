import React, { Fragment } from 'react';
import { ChildProps } from 'components/common/Form/types/interfaces';
import { buildClassName } from 'components/common/utils/helpers';
import './IconButton.scss';
import Icon from '../../Icons';
import Button, { ButtonType } from '../Button';
import ButtonToolTip from '../ButtonToolTip';

export interface IconButtonType extends ButtonType, ChildProps {
  icon: string;
  className?: string;
  toolTipText?: string;
  isExpanded?: boolean;
}

export function IconButton(props: IconButtonType): JSX.Element {
  const { toolTipText, id, onClick, kind, children, icon, isExpanded, className = '' } = props;
  const { style, isDisabled = false } = props;

  const baseClasses = buildClassName('IconButton');

  return (
    <Fragment>
      <Button
        kind={kind}
        id={id || ''}
        testId={id || 'icon-btn-' + icon}
        style={style}
        isDisabled={isDisabled}
        className={`${baseClasses} ${className}${isExpanded ? ' is-expanded' : ''}`}
        onClick={onClick}
      >
        <Icon name={icon} />
        <span className="link-text">{children}</span>
        {toolTipText && !isExpanded && <ButtonToolTip className="tool-tip" toolTipText={toolTipText} />}
      </Button>
    </Fragment>
  );
}
