import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { AllStoreSlices, LeadsSlice } from '../StoreSlices';

export const createLeadsSlice: StateCreator<
  AllStoreSlices,
  [['zustand/immer', never]],
  [['zustand/immer', never]],
  LeadsSlice
> = immer(set => ({
  leads: [],
  selectedLead: null,
  setLeads: (leads): void =>
    set(state => {
      state.leads = leads.map((lead, i) => ({ ...lead, currentIndex: i }));
      state.selectedLead = null;
    }),
  setSelectedLead: (leadIndex): void =>
    set(state => {
      state.selectedLead = leadIndex !== null ? state.leads[leadIndex] : null;
    })
}));
