import React from 'react';
interface FormGroupProps<T> {
  children?: React.ReactNode;
  data?: T;
}

/* 8/12/21 We discussed removing this component, as it's just a div with a default style.  But John-Paul said "at some point we'll need something to consolidate Form / Form providers
 in a way that reduces imports and boiler plate code downstream. There's a lot of duplication there and that was the intent around FromGroup".  Therefore, we are keeping this with the assumption 
 that this will be doing a lot more in the future */
export function FormGroup<T>({ children }: FormGroupProps<T>): JSX.Element {
  return <div className="form-group">{children}</div>;
}

export default FormGroup;