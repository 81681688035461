import { traverse } from 'components/common/utils/helpers';
import React, { Fragment, useEffect } from 'react';
import { ContextValue, Ctx, useCtx } from '../FormDataProvider/FormDataProvider';
import useClientValidation, { Rule, ruleFactory } from '../hooks/UseClientValidation';
import useSync from '../hooks/UseSync';
import SelectInput, { SelectInputProps } from '../SelectInput';
import { INPUT_TYPES } from '../types/enums';
import { FORM_ERRORS } from '../types/types';
import { ValidationMessage } from '../ValidatedInput/ValidationMessage';

export interface ValidatedSelectInputProps extends SelectInputProps {
  noPadding?: boolean;
  source: string;
  value?: string;
  rules?: Rule[];
}

const ValidatedSelectInput = <T extends typeof FORM_ERRORS>(props: ValidatedSelectInputProps): JSX.Element => {
  const { source, value, id, label, name, required, rules, onChange, onBlur } = props;
  const [provided, setProvided] = useCtx() as ContextValue<T>;
  const [, , syncClientErrors] = useSync<string[], T>(Ctx, `CLIENT_ERRORS.${source}`);

  const inputName = label && !name ? label.replace(/\s+/g, '-') : name || '';
  const allRules = [...(rules || []), ...(required ? [ruleFactory.required()] : [])];
  const [clientErrors, clientValidator, clearClientValidations] = useClientValidation(
    value?.toString() || '',
    source,
    allRules,
    INPUT_TYPES.TEXT,
    inputName
  );

  const serverErrors = provided?.SERVER_ERRORS && source ? traverse(provided.SERVER_ERRORS, source) : [];
  const allErrors = [...clientErrors, ...serverErrors];

  useEffect(() => {
    syncClientErrors(clientErrors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientErrors]);

  return (
    <Fragment>
      <SelectInput
        {...props}
        label={label + (required ? ' *' : '')}
        id={id}
        defaultVal={value}
        onChange={(e): void => {
          onChange && onChange(e);

          if (clientErrors.length > 0) {
            clearClientValidations();
          }
          if (serverErrors.length > 0) {
            setProvided(state => ({ ...state, SERVER_ERRORS: { ...state.SERVER_ERRORS, [source]: [] } }));
          }
        }}
        onBlur={async (e): Promise<void> => {
          if (await clientValidator()) {
            onBlur && onBlur(e);
          }
        }}
      />
      <ValidationMessage errors={allErrors} />
    </Fragment>
  );
};

export { ValidatedSelectInput, ValidatedSelectInput as default };
