import React from 'react';
import SyncedInput from '.';
import { INPUT_MODES, INPUT_TYPES } from '../types/enums';
import ValidatedInput, { ValidatedInputProps } from '../ValidatedInput';

const SyncedTextInput = (props: ValidatedInputProps): JSX.Element => {
  const { source, className, onBlur: onBlurProp, onChange: onChangeProp, noPadding = false } = props;

  return (
    <SyncedInput
      source={source}
      render={(value, onBlur, onChange): JSX.Element => (
        <ValidatedInput
          {...props}
          value={value || ''}
          onBlur={(e): void => {
            onBlur && onBlur(e);
            onBlurProp && onBlurProp(e);
          }}
          onChange={(e): void => {
            onChange && onChange(e);
            onChangeProp && onChangeProp(e);
          }}
          type={INPUT_TYPES.TEXT}
          inputMode={INPUT_MODES.TEXT}
          className={(className ? className : '') + 'input-container'}
          noPadding={noPadding}
        />
      )}
    />
  );
};

export default SyncedTextInput;
