import { ValidationMode } from '../hooks/UseClientValidation';

export const FORM_ERRORS = {
  SERVER_ERRORS: {} as Record<string, string[]>,
  CLIENT_ERRORS: {} as Record<string, string[]>,
  ERROR_STATE: function (): { CLIENT_ERRORS: string[][]; SERVER_ERRORS: string[][] } {
    return {
      CLIENT_ERRORS: traverse(this.CLIENT_ERRORS),
      SERVER_ERRORS: traverse(this.SERVER_ERRORS)
    };
  }
} as const;

const UPDATE_TRACKER = { UPDATE_TRACKER: 0, UPDATE_MESSAGE: '' };

export type Validator = (mode?: ValidationMode) => Promise<boolean>;

export type ValidationState = {
  validators: Record<string, Validator>;
  pendingValidations: Record<string, Promise<boolean>>;
  validationErrors: Record<string, string[]>;
};

export type DefaultFormStateType<T> = T & typeof FORM_ERRORS & typeof UPDATE_TRACKER;

export function defaultFormState<T>(obj: T): DefaultFormStateType<T> {
  return {
    ...obj,
    ...FORM_ERRORS,
    ...UPDATE_TRACKER
  };
}

export function defaultState<T>(obj: T): T {
  return {
    ...obj,
    ...FORM_ERRORS,
    ...UPDATE_TRACKER
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function traverse(obj: Record<string, any>, allErrors: string[][] = []): string[][] {
  for (const key in obj) {
    const val = obj[key];
    if (val && val.map && val.length > 0) {
      allErrors.push(val);
    } else if (val && !val.length && typeof val === 'object') {
      traverse(val, allErrors);
    }
  }
  return allErrors;
}
