import { InputProps } from 'components/common/Form/types/interfaces';
import Icon from 'components/common/Icons';
import styles from './SelectInput.module.scss';

export interface SelectInputProps extends InputProps {
  options: { label: string; value: string | number }[];
  noPadding?: boolean;
  placeholder?: string;
  className?: string;
  defaultVal?: string | number;
}

export default function SelectInput(props: SelectInputProps): JSX.Element {
  const {
    label,
    options,
    name,
    id,
    onBlur,
    onChange,
    required,
    autocomplete,
    value,
    defaultVal,
    placeholder,
    className = '',
    noPadding
  } = props;

  // The select element must be either controlled or uncontrolled, meaning you should specify
  // either the value or the default value. If the value is specified, we remove defaultValue,
  // otherwise we remove value.
  const valueProps = { value, defaultValue: defaultVal };
  if (value !== undefined) {
    delete valueProps.defaultValue;
  } else {
    delete valueProps.value;
    valueProps.defaultValue = valueProps.defaultValue || '';
  }

  const inputName = label && !name ? label.replace(/\s+/g, '-') : name;
  const testId = id ? id : label?.replace(' ', '-').toLocaleLowerCase();

  return (
    <label
      id="status-input"
      className={`${styles['ccmp-selectInput']} ${className} ${styles['input-field-container']} ${
        noPadding ? styles['no-padding'] : ''
      } `}
    >
      {label && <span className={styles['input-field-label']}>{label}</span>}
      <div className={styles['select-container']}>
        <select
          name={inputName}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          aria-required={required}
          autoComplete={autocomplete}
          {...valueProps}
          data-testid={`${testId}-select`}
        >
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {options.map((opt, index) => (
            <option
              value={opt.value}
              aria-selected={value === opt.value}
              key={opt.label + opt.value + index}
              data-testid={`SelectInput_${opt.value}-option`}
            >
              {opt.label}
            </option>
          ))}
        </select>
        <Icon name="chevronDown" />
      </div>
    </label>
  );
}
