import React from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './MyAccount.module.scss';
import { displayImgSrc } from 'components/pages/Profile/profileHelper';
import { PhotoType } from 'components/types/interfaces';
import { useHistory } from 'react-router-dom';
import ButtonToolTip from '../Button/ButtonToolTip';
import Icon from '../Icons';

export interface MyAccountProps {
  agentPortrait: number;
  agentName: string;
  agentCompany?: string;
  isExpanded?: boolean;
  showTooltip: boolean;
  toggleMenu: (isExpanded: boolean) => void;
}

const MyAccount: React.FunctionComponent<MyAccountProps> = ({
  agentPortrait,
  agentName,
  agentCompany,
  isExpanded,
  showTooltip,
  toggleMenu
}) => {
  const history = useHistory();

  const handleClick = (): void => {
    history.push('/profile');
    toggleMenu(false);
  };

  const showTooltipWhenCollapsed = !isExpanded && showTooltip;

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={styles[`${isExpanded ? 'account-info-container' : 'collapsed'}`]}
      data-testid="account-info-container"
    >
      {showTooltipWhenCollapsed && <ButtonToolTip toolTipText="My Profile" className={styles.tooltip} />}
      {agentPortrait > 0 ? (
        <img
          className={styles['agent-portrait']}
          alt={agentName}
          src={displayImgSrc(agentPortrait, PhotoType.Thumbnail)}
        />
      ) : (
        <Icon name="avatar" className={styles['agent-portrait']} />
      )}
      <div className={styles['agent-contact-info']}>
        <div className={styles['agent-name']}>{agentName}</div>
        {agentCompany && (
          <div className={styles['agent-company']} data-testid="agent-company">
            {agentCompany}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
