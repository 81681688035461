import React, { useContext, createContext, useState } from 'react';
import { ChildProps } from '../types/interfaces';
import { DefaultFormStateType, defaultFormState, ValidationState } from '../types/types';

export type ContextValue<T> = [DefaultFormStateType<T>, React.Dispatch<React.SetStateAction<DefaultFormStateType<T>>>];

export function createCtx<T>(): readonly [() => ContextValue<T>, React.Context<ContextValue<T>>] {
  const ctx = createContext<ContextValue<T>>({} as ContextValue<T>);
  function useCtx(): ContextValue<T> {
    const c = useContext(ctx);
    if (!c) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }
  return [useCtx, ctx] as const;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const [useCtx, Ctx] = createCtx<any>();

interface ProviderProps<T> extends ChildProps {
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
}

export const DefaultValidationContext = {
  validators: {},
  pendingValidations: {},
  validationErrors: {}
} as ValidationState;
export const ValidationContext = createContext(DefaultValidationContext);

export default function FormDataProvider<T>({ state, setState, children }: ProviderProps<T>): JSX.Element {
  const [validators] = useState({
    validators: {},
    pendingValidations: {},
    validationErrors: {}
  });

  return (
    <Ctx.Provider value={[{ ...defaultFormState({}), ...state }, setState]}>
      <ValidationContext.Provider value={validators}>{children}</ValidationContext.Provider>
    </Ctx.Provider>
  );
}
