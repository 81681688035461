import React, { useCallback, useEffect, useRef, useState } from 'react';
import { buildClassName } from '../utils/helpers';
import { ChildProps } from '../Form/types/interfaces';
import { useParams } from 'react-router-dom';
import GreenMapPin from 'assets/svg/map-green-pin.svg';
import './SimpleMap.scss';

export interface EditListingParamType {
  id: string;
}

export interface SimpleMarkerData {
  listingId: number;
  position: google.maps.LatLngLiteral;
}

// represents the expected parameters to the MapContainer element
// isLibsLoaded - true if supporting (Google) libraries have been loaded, false if not loaded or loading
// listingId - 0 if new listing, otherwise the inventoryId from the DB
// location - location from the address autocomplete, null if not set
//            (only set if the user selects an address, will be null on start of new or existing address)
// viewport - viewport settings from the address autocomplete, null if not set
//            (only set if the user selects an address, will be null on start of new or existing address)
// onMove - listener for marker position changes. If listener is supplied, we will not try to update the DB.
export interface MapLocationType extends ChildProps {
  isMapApiLoaded: boolean;
  location: google.maps.LatLngLiteral;
  viewport?: google.maps.LatLngBoundsLiteral;
  saveMarker: (pos: google.maps.LatLngLiteral) => void;
}

//const markerEndPoint = '/draftlisting/simplemarker/';
// once libs have been loaded, they have been loaded globally, no need to rely on values passed in
let globalLibsLoaded = false;
// default values needed in more than one place
const defaultCenter = { lat: 39.90973623453719, lng: -98.7890625 } as SimpleMarkerData['position'];
const defaultZoom = 4;
const markerZoom = 18;

const initMap = (
  mapElement: React.MutableRefObject<HTMLDivElement>,
  initialCenter: SimpleMarkerData['position'],
  initialZoom: number
): google.maps.Map => {
  // set up the map defaults
  const mapDefaults = {
    center: initialCenter,
    fullscreenControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      mapTypeIds: [
        google.maps.MapTypeId.HYBRID,
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.SATELLITE,
        google.maps.MapTypeId.TERRAIN
      ]
    },
    rotateControl: false,
    scaleControl: false,
    streetViewControl: false,
    zoom: initialZoom,
    zoomControl: true
  } as google.maps.MapOptions;

  // if we found the map element, add the map to it
  return new google.maps.Map(mapElement.current, mapDefaults) as google.maps.Map;
};

const isGoodLocation = (pos: google.maps.LatLngLiteral | null | undefined): boolean => {
  return !!(pos && pos.lat && pos.lng);
};

// location and viewport are updated by the address autocomplete
// if the location updates and we do not have a marker, set the marker and send update
// if the location updates and we already have a marker, do nothing
export default function SimpleMap(props: MapLocationType): JSX.Element {
  const { isMapApiLoaded, location, saveMarker, viewport } = props;
  const { id: listingId = 0 } = useParams<EditListingParamType>();

  const className = buildClassName('MapContainer');
  const mapElementRef = useRef<HTMLDivElement>();
  const hasMapLoaded = useRef(false);
  const propertyMap = useRef<google.maps.Map>();
  const marker = useRef<google.maps.Marker>();
  const markerPosition = useRef<google.maps.LatLngLiteral>();
  const [markerLoaded, setMarkerLoaded] = useState(false);
  const hasMarker = useCallback((): boolean => {
    // if the marker has been set and has a map value, then the marker is on the map
    return !!(marker && marker?.current?.getMap && marker.current.getMap());
  }, []);

  const removeMarker = useCallback((): void => {
    if (propertyMap.current) {
      if (marker.current && marker.current.setMap) {
        marker.current.setMap(null);
        google.maps.event.clearInstanceListeners(marker.current);
        marker.current = undefined;
      }
    }
  }, []);

  const addMarker = useCallback(
    (position: google.maps.LatLngLiteral): void => {
      removeMarker();

      const listingMarker = new google.maps.Marker({
        crossOnDrag: false,
        draggable: true,
        icon: GreenMapPin,
        map: propertyMap.current,
        position: position
      } as google.maps.MarkerOptions);

      // update marker position after marker moved
      google.maps.event.addListener(listingMarker, 'dragend', () => {
        if (listingMarker.getPosition()) {
          const pos = {
            lat: listingMarker.getPosition()?.lat(),
            lng: listingMarker.getPosition()?.lng()
          } as google.maps.LatLngLiteral;

          if (!isGoodLocation(pos)) {
            return;
          }

          markerPosition.current = pos;
          saveMarker(pos);
        }
      });

      marker.current = listingMarker;
    },
    [saveMarker, removeMarker]
  );

  const updateMarkerAndMapPosition = useCallback(
    (locationPosition: MapLocationType['location'], locationViewport: MapLocationType['viewport'] | null): void => {
      // if there is no map, then bail
      if (!(propertyMap.current && propertyMap.current.panTo)) {
        return;
      }

      if (isGoodLocation(locationPosition)) {
        // if we have a viewport, use its bounds
        // otherwise use the location and zoom close
        if (locationViewport) {
          propertyMap.current.fitBounds(locationViewport);
        } else {
          propertyMap.current.panTo(locationPosition);
          propertyMap.current.setZoom(18);
        }

        // if the marker has already been created, use it
        // otherwise, create it
        if (hasMarker()) {
          marker.current?.setPosition(locationPosition);
          marker.current?.setMap(propertyMap.current);
        } else {
          addMarker(locationPosition);
        }
      } else {
        // if no location, clear the marker if it exists and reset to default
        if (hasMarker()) {
          marker.current?.setMap(null);
        }

        // reset map position to default
        propertyMap.current.panTo(defaultCenter);
        propertyMap.current.setZoom(defaultZoom);
      }
    },
    [addMarker, hasMarker]
  );

  // get the marker, if it exists
  useEffect(() => {
    // we currently do not expect listingId to change, but let's future-proof and reset if it does
    removeMarker();

    // fetch the listing info even if we can't draw the map yet, we can save the data
    if (listingId && isGoodLocation(location)) {
      markerPosition.current = location;
    }

    setMarkerLoaded(true);

    // return function will be called on unmount
    return (): void => removeMarker();
  }, [listingId, removeMarker, location]);

  // load the map if we have what we need to do it
  useEffect(() => {
    // if we've already built the map for this control, bail
    // if we're waiting for the marker initial position, bail
    // if we have not loaded the google libs, bail
    if (hasMapLoaded.current || !markerLoaded || (!isMapApiLoaded && !globalLibsLoaded)) {
      return;
    }

    // if either is true, then permanent libsLoaded flag goes to true
    globalLibsLoaded = isMapApiLoaded || globalLibsLoaded;

    // so we've what we need to load the map, but we also want
    let mapCenter = defaultCenter;
    let mapZoom = defaultZoom;
    if (isGoodLocation(markerPosition.current)) {
      mapCenter = markerPosition.current as google.maps.LatLngLiteral;
      mapZoom = markerZoom;
    }

    propertyMap.current = initMap(mapElementRef as React.MutableRefObject<HTMLDivElement>, mapCenter, mapZoom);

    hasMapLoaded.current = true;

    // now that we have a map, if we have a good marker position, add the marker
    if (isGoodLocation(markerPosition.current)) {
      google.maps.event.addListenerOnce(propertyMap.current, 'tilesloaded', () => {
        if (!hasMarker()) {
          // just add the marker, don't need to update the DB, we just got it from there
          addMarker(markerPosition.current as google.maps.LatLngLiteral);
        }
      });
    } else if (isGoodLocation(location)) {
      // only add listener to add marker on map load if we have a marker location to place
      google.maps.event.addListenerOnce(propertyMap.current, 'tilesloaded', () => {});
    }
  }, [addMarker, isMapApiLoaded, location, markerLoaded, updateMarkerAndMapPosition, viewport, hasMarker]);

  // update marker based on values from autocomplete if we do not already have a marker
  useEffect(() => {
    // if the google libs have been loaded
    // and the map has been loaded
    // and we've finished trying to load a marker
    // and we don't already have a marker
    // and the location is good, then move the map and add the marker
    if (globalLibsLoaded && hasMapLoaded.current && markerLoaded && !hasMarker() && isGoodLocation(location)) {
      updateMarkerAndMapPosition(location, viewport);
    }
  }, [hasMarker, location, markerLoaded, updateMarkerAndMapPosition, viewport]);

  return (
    <div>
      <div ref={mapElementRef as React.RefObject<HTMLDivElement>} className={className}>
        Loading...
      </div>
    </div>
  );
}

//
// Loading the script and accessing the AdvanceMapSeller
// useEffect(() => {
//   async function loadScript ():Promise<void> {
//     await includeAdvancedMappingScripts();
//   }
//   loadScript();
//   console.warn('ESE', (window as any).LOAAdvancedMapSeller);
// }, []);
//
// Known issue:
//  Doing a manual refresh creates a condition where the
//  page renders before the LOAAdvancedMapSeller has loaded
//

export const includeAdvancedMappingScripts = (): void => {
  appendScript('/assets/advanced-mapping.seller.js');
};

export const appendScript = (scriptToAppend: string): void => {
  const script = document.createElement('script');
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};
