import Button from '../Button/Button';

interface CommonModalButtonsProps {
  submitLabel?: string;
  cancelLabel?: string;
  disableSubmit?: boolean;
  disableCancel?: boolean;
  isDestructive?: boolean;
  formName?: string;
  onCancel: () => void;
  onSave?: () => void;
}

export default function CommonModalButtons(props: CommonModalButtonsProps): JSX.Element {
  const {
    submitLabel = 'Save',
    cancelLabel = 'Cancel',
    disableSubmit = false,
    disableCancel = false,
    isDestructive = false,
    formName,
    onCancel,
    onSave
  } = props;

  return (
    <div className="bottom-modal-buttons">
      <Button
        kind="tertiary"
        size="med"
        testId="modal-cancel-button"
        isDisabled={disableCancel}
        onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button
        kind={isDestructive ? 'destructive' : 'primary'}
        size="med"
        testId="modal-save-button"
        isDisabled={disableSubmit}
        onClick={onSave}
        type="submit"
        form={formName}
      >
        {submitLabel}
      </Button>
    </div>
  );
}
