import React from 'react';
import './ButtonToolTip.scss';
import { ChildProps } from 'components/common/Form/types/interfaces';
import { StylableType } from 'components/common/Types/types';
import { buildClassName } from 'components/common/utils/helpers';

export interface ButtonToolTip extends ChildProps, StylableType {
  isDisabled?: boolean;
  className?: string;
  toolTipText: string;
}

export default function BackButton(props: ButtonToolTip): JSX.Element {
  const { toolTipText, className = '' } = props;

  const baseClasses = buildClassName('ToolTip');

  return <span className={`${baseClasses} ${className}`}>{toolTipText}</span>;
}
