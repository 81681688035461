import { useContext, useEffect, useState } from 'react';
import { traverse, updateObjectByPath } from 'components/common/utils/helpers';

// eslint-disable-next-line @typescript-eslint/ban-types
export default function useSync<T, U, V = {}>(
  context: React.Context<[U, React.Dispatch<React.SetStateAction<U>>]>,
  source: string
): [T, React.Dispatch<React.SetStateAction<T>>, (uniqueData?: V) => void] {
  const [provided, setProvided] = useContext(context);
  const providedVal = traverse(provided, source);
  const [val, setVal] = useState<T>(providedVal);

  useEffect(() => {
    setVal(providedVal);
  }, [providedVal]);

  function sync(uniqueData?: V): void {
    setProvided((state: typeof provided) => {
      const syncValue = uniqueData != undefined && uniqueData != null ? uniqueData : val;
      const updated = updateObjectByPath(state, source, syncValue);
      return { ...state, ...updated };
    });
  }

  return [val, setVal, sync];
}
