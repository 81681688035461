import { useEffect, Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import Icons from '../Icons';
import { ChildProps } from '../Form/types/interfaces';
import './Modal.scss';
//https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/dialog_role

export interface ModalProps extends ChildProps {
  title?: string;
  isExpanded?: boolean;
  className?: string;
  close?: () => void;
  footer?: JSX.Element;
  // Allows pop-out elements to extend beyond the scope of the modal
  showOverflow?: boolean;
  // Controls if the modal contents / children will only be rendered in the modal (true)
  // or also inline (false) when the modal is not expanded.
  popupOnly?: boolean;
  showMobileBack?: boolean;
}

export default function Modal(props: ModalProps): JSX.Element {
  const [scrollTop, setScrollTop] = useState(0);
  const [el, setEl] = useState<Element>();
  const { title, className = '', children, isExpanded, close, footer, popupOnly, showMobileBack = true } = props;

  const createElement = (id: string, tag: string): HTMLElement => {
    const el = document.createElement(tag);
    el.id = id;
    document.body.appendChild(el);
    return el;
  };

  useEffect(() => {
    if (isExpanded && window && window.pageYOffset) {
      setScrollTop(window.pageYOffset);
    }
    if (!isExpanded && scrollTop > 0) {
      window.scrollTo(0, scrollTop);
    }
  }, [isExpanded, scrollTop]);

  useEffect(() => {
    const modalRoot = document.getElementById('modal-root') || createElement('modal-root', 'div');
    const el = document.createElement('div');
    setEl(el);
    modalRoot && modalRoot.appendChild(el);

    return function cleanup(): void {
      modalRoot && modalRoot.removeChild(el);
    };
  }, []);

  return el && isExpanded ? (
    ReactDOM.createPortal(
      <Fragment>
        <style
          dangerouslySetInnerHTML={{
            __html: `body { overflow: hidden; background-color: white }`
          }}
        />
        <div
          role="dialog"
          aria-labelledby={title}
          className={`modal-container ${className} ${props.showOverflow ? 'visible-overflow' : 'hidden-overflow'}`}
          data-testid="modal-container"
        >
          <div className="modal-header">
            {showMobileBack && (
              <span role="button" tabIndex={0} onClick={close} className="close-mobile" data-qa-id="modalCloseMobile">
                <Icons name="modalBackArrow" />
              </span>
            )}
            <div className="title-container">
              <div id={title} className="title">
                {title ? title : ''}
              </div>
            </div>
            <span role="button" tabIndex={0} onClick={close} className="close" data-qa-id="modalClose">
              <Icons name="close" />
            </span>
          </div>
          <div className={'scroll-container'}>
            <div className="modal-body">{children}</div>
          </div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
        <div style={{ top: scrollTop }} className="backdrop" />
      </Fragment>,
      el,
      title
    )
  ) : popupOnly ? (
    <Fragment />
  ) : (
    <Fragment>{children}</Fragment>
  );
}
