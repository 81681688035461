import { useState, useEffect } from 'react';

export default function useApiLoader(apiUrl: string, isPrevLoaded = false): boolean {
  const [isLoaded, setIsLoaded] = useState(isPrevLoaded);

  useEffect(() => {
    let scriptTag: HTMLScriptElement;
    function onLoad(): void {
      setIsLoaded(true);
    }

    if (!isLoaded) {
      scriptTag = document.createElement('script');
      scriptTag.src = apiUrl;
      scriptTag.async = true;
      document.body.appendChild(scriptTag);
      scriptTag.addEventListener('load', onLoad);
      return (): void => scriptTag.removeEventListener('load', onLoad);
    }
  }, [apiUrl, isLoaded]);

  return isLoaded;
}
