import { fetchLandApiData } from 'components/common/utils/helpers';
import { CityLookupResults } from 'components/pages/Profile/interface';
import { StateName } from 'components/types/enums';
import React, { useEffect } from 'react';
import { Rule } from '../hooks/UseClientValidation';
import SyncedTextInput from '../SyncedInput/SyncedTextInput';
import useSync from '../hooks/UseSync';
import { Ctx } from '../FormDataProvider/FormDataProvider';

export const cityFeederRule = (stateId: number): Rule => {
  return {
    id: 'city',
    message: 'This city does not exist in the selected state',
    validator: async (city: string): Promise<boolean> => {
      const url = `/DetailsMap/verify-city/?q=${city}&sid=${stateId}`;

      // Will shortcircuit and not make City Lookup request, because null or empty is validated by
      // required validation rule.
      if (!city) {
        return true;
      }

      window.verifyCityRequest = true;

      const response = await fetchLandApiData<CityLookupResults>('cityFeedrValidation', url, {
        method: 'GET'
      });

      if (response.ok) {
        window.verifyCityRequest = false;
        return true;
      } else {
        window.verifyCityRequest = false;
        const stateName = StateName[stateId];
        console.error(`Could Not locate City: ${city} in ${stateName}`);
        return false;
      }
    }
  };
};

interface CityInputProps {
  source: string;
  stateIdSource: string;
  selectedStateId?: number;
  label?: string;
  required?: boolean;
}
export function CityInput(props: CityInputProps): JSX.Element {
  const { source, stateIdSource, selectedStateId, label = 'City', required } = props;
  const [stateId] = useSync<number, number>(Ctx, stateIdSource);

  useEffect(() => {
    const el = document.getElementById('city');
    el?.focus();
    el?.blur();
  }, [selectedStateId]);

  return (
    <SyncedTextInput
      name="city"
      source={source}
      label={label}
      maxLength={75}
      isWatchedValidation={true}
      required={required}
      rules={[cityFeederRule(selectedStateId || stateId)]}
    />
  );
}
