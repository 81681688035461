import loadable, { LoadableComponent } from '@loadable/component';

export interface Route {
  path: string;
  exact: boolean;
  hideAppShell?: boolean;
  privateRoute: boolean;
  loadable: LoadableComponent<unknown>;
}
/*
 * webpackChunkName must be the exact same as the static displayName of the component you're referencing.
 * This is needed to determine what static resources to load (js and css) in server.tsx
 * When creating a route the route needs a .scss file with at least
 * one class (.main as an example) in order to create a .css chunk for that route.
 */
/* order of routes matters here.  Route will return the first route that matches the path provided. */
const routes: Route[] = [
  {
    path: '/',
    exact: true,
    privateRoute: true,
    /*
    Temporary Change: Setting '/' path to Listing Manager as the default home page 
    TODO: revert to "Home" when working on Feature 2402160 - MH 2.0 Dashboard Redesign [https://tfs.prd.costargroup.com/CoStarCollection/CoStar%20Land/_workitems/edit/2402160]
     */
    loadable: loadable(() => import(/* webpackChunkName: "Listing Manager" */ `../components/pages/ListingManager`))
  },
  {
    path: '/leads',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "Leads" */ `../components/pages/Leads`))
  },
  {
    path: '/leads/:type/:pageNumber',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "Leads" */ `../components/pages/Leads`))
  },
  {
    path: '/listing-manager',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "Listing Manager" */ `../components/pages/ListingManager`))
  },
  //The following is an optional path, this needs to be at the end so it doesn't break routes that also contain only a listingStatusFilter and/or pageNumber params
  {
    path: '/listing-manager/:listingStatusFilter/:pageNumber',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "Listing Manager" */ `../components/pages/ListingManager`))
  },
  {
    path: '/listing/add',
    exact: true,
    hideAppShell: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "Listing" */ `../components/pages/Listing`))
  },
  {
    path: '/listing/edit/:id',
    exact: true,
    hideAppShell: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "Listing" */ `../components/pages/Listing`))
  },
  {
    path: '/published',
    exact: true,
    hideAppShell: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "Published" */ `../components/pages/Listing/Published`))
  },
  {
    path: '/profile',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "Profile" */ `../components/pages/Profile`))
  },
  {
    path: '/billing-manager',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "BillingManager" */ `../components/pages/BillingManager`))
  },
  {
    path: '/billing-manager/:pageNumber',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "BillingManager" */ `../components/pages/BillingManager`))
  },

  {
    path: '/listing-stats',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "ListingStats" */ `../components/pages/ListingStats`))
  },

  {
    path: '/listing-stats/:active/:dateRange/:pageNumber',
    exact: true,
    privateRoute: true,
    loadable: loadable(() => import(/* webpackChunkName: "ListingStats" */ `../components/pages/ListingStats`))
  },

  {
    path: '/listing-stats/:id',
    exact: true,
    privateRoute: true,
    loadable: loadable(
      () =>
        import(
          /* webpackChunkName: "ListingSpecificAnalytics" */ `../components/pages/ListingStats/ListingSpecificAnalytics`
        )
    )
  }
  // Fallback Route if nothing matches.
  //   {
  //     path: '/*',
  //     exact: false,
  //     fileName: 'pages/Error/index.tsx',
  //     loadable: loadable(() => import(/* webpackChunkName: "Error" */ `../pages/Error`))
  //   }
];

export default routes;
