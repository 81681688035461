import { useState, useEffect } from 'react';
import useApiLoader from '../utils/useApiLoader';

const googleApiKey = 'key=AIzaSyCvtvmtxTW9V3N4SW7-QvFPV5k1O6pmkds';

// google script for tables
// we will need this for the Advanced Map part, just proving it works here
const jsApiUrl = 'https://www.gstatic.com/charts/loader.js?' + googleApiKey;

// google script for map
const mapApiUrl =
  'https://maps.googleapis.com/maps/api/js?libraries=geometry,visualization,drawing,places&channel=land&' +
  googleApiKey;

export default function useMapLoader(): boolean {
  const [isLoaded, setIsLoaded] = useState(false);
  const isMapLoaded = useApiLoader(mapApiUrl, typeof google !== 'undefined' && typeof google.maps !== 'undefined');
  const isJSApiLoaded = useApiLoader(jsApiUrl);

  useEffect(() => {
    setIsLoaded(isMapLoaded && isJSApiLoaded);
  }, [isMapLoaded, isJSApiLoaded]);

  return isLoaded;
}
